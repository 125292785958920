import { Box, CardMedia, Grid, Hidden, Stack, Typography } from "@mui/material"
import React from "react"
import NFTImage0 from "../../assets/images/about/nft_image_0.png"
import NFTImage1 from "../../assets/images/about/nft_image_1.png"
import NFTImage2 from "../../assets/images/about/nft_image_2.png"
import NFTImage3 from "../../assets/images/about/nft_image_3.png"
import SectionTitleBG from "../../assets/images/section/section_title_bg.png"
import classes from "./About.module.scss"

const contents = {
  welcome: "Welcome to the",
  branding: "BaseApeGame",
  summary: `
  Welcome to BaseApeGame, where you can earn tokens and NFTs by participating in the war between Humans and Apes. In this world, humans and apes are divided into two opposing camps. With the establishment of the camp, NFTs of different camps can resist or even intercept other's assets. Some of you will choose to participate in battles for rewards and develop your own group to gain a stronger foothold in the war.
  `,
}

export const About = () => {
  return (
    <div>
      <Hidden mdUp>
        <div className={classes["m-introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                About
              </Typography>
            </div>

            <div className={classes["nft-images-container"]}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage0} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage1} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage3} />
                </Grid>
              </Grid>
            </div>
            <div className={classes["nft-introduce"]}>
              <Stack>
                <Typography component="div">
                  <Box
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #018a4b, #1beb65, #1beb65)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: 31,
                      fontWeight: 700,
                      paddingBottom: 0,
                      marginBottom: 0,
                      lineHeight: "40px",
                    }}
                  >
                    {contents.welcome}
                  </Box>
                  <Box
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #018a4b, #1beb65, #1beb65)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: 27,
                      fontWeight: 700,
                      paddingBottom: 0,
                      marginBottom: 0,
                    }}
                  >
                    {contents.branding}
                  </Box>
                </Typography>
                <Typography component="div">
                  <Box
                    style={{
                      paddingBottom: 30,
                      fontSize: 19,
                      lineHeight: "30px",
                      color: "#FFF",
                    }}
                  >
                    {contents.summary}
                  </Box>
                </Typography>
              </Stack>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdDown>
        <div className={classes["introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                About
              </Typography>
            </div>

            <div className={classes["nft-images-container"]}>
              <Grid container spacing={4}>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage0} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage1} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage3} />
                </Grid>
              </Grid>
            </div>
            <div className={classes["nft-introduce"]}>
              <Stack>
                <Typography component="div">
                  <Box
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #018a4b, #1beb65, #1beb65)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: 51,
                      fontWeight: 700,
                      paddingBottom: 0,
                      marginBottom: 0,
                      lineHeight: "51px",
                    }}
                  >
                    {contents.welcome}
                  </Box>
                  <Box
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #018a4b, #1beb65, #1beb65)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: 31,
                      fontWeight: 700,
                      paddingBottom: 0,
                      marginBottom: 0,
                    }}
                  >
                    {contents.branding}
                  </Box>
                </Typography>
                <Typography component="div">
                  <Box
                    style={{
                      paddingBottom: 30,
                      fontSize: 23,
                      lineHeight: "40px",
                      color: "#FFF",
                    }}
                  >
                    {contents.summary}
                  </Box>
                </Typography>
              </Stack>
            </div>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
