import {
  Box,
  Button,
  CardMedia,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import { FaMedium, FaTelegram, FaTwitter } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import HeaderBG from "../../assets/images/header/header_bg.png"
import Logo from "../../assets/images/header/logo.png"
import { ToggleLoginButton } from "../../client"
import { MediaChannels } from "../../constants"
import { SideDrawer } from "../drawer/SideDrawer"
import classes from "./Header.module.scss"

export const Header = () => {
  const navItems = [
    {
      title: "Token",
    },
    {
      title: "NFT",
    },
    {
      title: "GAME",
    },
    {
      title: "ROADMAP",
    },
  ]

  const navigate = useNavigate()
  const match = useLocation()

  const goToRoadMap = () => {
    if (match === "/") {
      scrollToAnchor("roadmap")
    } else {
      navigate("/")
      scrollToAnchor("roadmap")
    }
  }

  const scrollToAnchor = (anchorName) => {
    console.log("scroll to ", anchorName)
    setTimeout(() => {
      if (anchorName) {
        let anchorElement = document.getElementById(anchorName)
        if (anchorElement) {
          anchorElement.scrollIntoView({ block: "start", behavior: "smooth" })
        }
      }
    }, 500)
  }

  return (
    <div>
      <Hidden mdUp>
        <Stack
          height={"100%"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"10px"}
          backgroundColor={"#000"}
          style={{
            paddingBottom: 10,
            borderBottom: "2px solid",
            borderTop: "0 solid",
            borderLeft: "0 solid",
            borderRight: "0 solid",
            borderImage:
              "linear-gradient(to left, #018a4b, #15d45f, #15d45f, #018a4b) 1",
          }}
        >
          <Box>
            <img src={Logo} alt="head logo" height={"50"} />
          </Box>

          {/* <Box className={classes["header-logo"]}>
            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
              <a href="/">
                <img src={Logo} alt="apps world" height={"35"} />
              </a>
            </Stack>
          </Box> */}
          <Box style={{ marginRight: 0 }}>
            <SideDrawer scrollToAnchor={scrollToAnchor} />
          </Box>
        </Stack>
      </Hidden>
      <Hidden mdDown>
        <div className={classes.Header}>
          <Hidden mdDown>
            <div
              className={classes["header-content"]}
              style={{
                borderBottom: "3px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to left, #018a4b, #15d45f, #15d45f, #018a4b) 1",
              }}
            >
              <Stack
                height={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box className={classes["header-logo"]}>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <a href="/">
                      <img src={Logo} alt="apps world" height={"80"} />
                    </a>
                  </Stack>
                </Box>
                <Box className={classes["header-nav"]}>
                  <CardMedia
                    title="background"
                    image={HeaderBG}
                    style={{
                      backgroundSize: "100% 120px",
                      height: "120px",
                      paddingLeft: 60,
                      paddingRight: 60,
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      style={{
                        height: 120,
                      }}
                    >
                      {navItems.map((item, index) => {
                        return (
                          <Typography
                            component="div"
                            key={index}
                            style={{
                              paddingBottom: 20,
                            }}
                          >
                            <Button
                              size={"large"}
                              onClick={() => {
                                if (item.title === "ROADMAP") {
                                  goToRoadMap()
                                } else if (item.title === "NFT") {
                                  if (match.pathname !== "/nft") {
                                    navigate("/nft")
                                  }
                                } else if (item.title === "Token") {
                                  if (match.pathname !== "/token") {
                                    navigate("/token")
                                  }
                                } else if (item.title === "GAME") {
                                  if (match.pathname !== "/game") {
                                    navigate("/game")
                                  }
                                }
                              }}
                              className={classes["nav-button"]}
                              style={
                                match.pathname.substring(1).toUpperCase() ===
                                item.title.toUpperCase()
                                  ? { color: "yellow" }
                                  : {}
                              }
                            >
                              {item.title}
                            </Button>
                          </Typography>
                        )
                      })}

                      <IconButton
                        href={MediaChannels.medium}
                        style={{
                          // margin: "0 10px",
                          // borderRadius: 6,
                          // padding: "5px",
                          // background:
                          //   "linear-gradient(to right, #434bba, #6d3a88)",
                          color: "#FFF",
                          marginBottom: 20,
                        }}
                      >
                        <FaMedium color="#FFF" size={30} />
                      </IconButton>
                      <IconButton
                        href={MediaChannels.telegram}
                        style={{
                          // margin: "0 20px",
                          // borderRadius: 6,
                          // padding: "5px",
                          // background:
                          //   "linear-gradient(to right, #434bba, #6d3a88)",
                          color: "#FFF",
                          marginBottom: 20,
                        }}
                      >
                        <FaTelegram color="#FFF" size={30} />
                      </IconButton>
                      <IconButton
                        href={MediaChannels.twitter}
                        style={{
                          // margin: "0 20px",
                          // borderRadius: 6,
                          // padding: "5px",
                          // background:
                          //   "linear-gradient(to right, #434bba, #6d3a88)",
                          color: "#FFF",
                          marginBottom: 20,
                        }}
                      >
                        <FaTwitter color="#FFF" size={30} />
                      </IconButton>
                    </Stack>
                  </CardMedia>
                </Box>
                <Box>
                  <ToggleLoginButton />
                </Box>
              </Stack>
            </div>
          </Hidden>
        </div>
      </Hidden>
    </div>
  )
}
