import {
  Box,
  Button,
  CardMedia,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import MintButtonIcon from "../../assets/images/headbanner/mint_btn.png"
import PercentBG from "../../assets/images/roadmap/percent.png"
import SectionTitleBG from "../../assets/images/section/section_title_bg.png"
import classes from "./RoadMap.module.scss"

const roadmapContents = [
  {
    percentage: 1,
    title: "Genesis",
    description: `
    The official website will be launch and the contract will be open source and available for users, the community and social media channel will be launched. When all the previous is done.
    `,
  },
  {
    percentage: 25,
    title: "Token & NFT",
    description: `
    The card pledge game will be launched with 10,000 unique cards, waiting for players to mine all tokens. NFT will be available on the platforms such as NFTtrade. Tokens will be available on the platforms such as CMC.
    `,
  },
  {
    percentage: 50,
    title: "GameFi",
    description: `Launching the combat part of the game, the NFT holders can initiate a valuation adjustment mechanism (VAM) with other NFT holders by betting against each other. The winner will have the opponent's cards and other betting money.
    `,
  },
  {
    percentage: 75,
    title: "Jackpot",
    description: `All NFT and token holders will split the LP bonus. Users can reallocate the assets into the metaverse ecosystem for in-game boosts, and privileges or sell them directly into the public markets to generate additional revenue.`,
  },
  {
    percentage: 100,
    title: "DAO",
    description: `The development of version 2.0 will be determined through mechanisms such as voting. All NFT and token holders within the community retain the right to raise proposals and vote on these proposals. The proposals may include the change of staking rewards or updates on the blockchain.
    `,
  },
]

export const RoadMap = () => {
  return (
    <div>
      <Hidden mdDown>
        <div className={classes["introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                id="roadmap"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                RoadMap
              </Typography>
            </div>
            <Hidden mdUp>
              <div className={classes["m-title-container"]}>
                <CardMedia
                  component={"img"}
                  src={SectionTitleBG}
                  style={{
                    width: 185,
                  }}
                />
                <Typography
                  id="roadmap"
                  style={{
                    backgroundImage:
                      "linear-gradient(to bottom, #1bec65, #018a4b)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                    position: "absolute",
                    width: 130,
                    textAlign: "center",
                    top: -30,
                    fontSize: 23,
                    letterSpacing: 2,
                    textTransform: "uppercase",
                    fontWeight: 700,
                  }}
                >
                  RoadMap
                </Typography>
              </div>
            </Hidden>
            <div className={classes["nft-images-container"]}>
              <Stack direction={"column"} spacing={3}>
                {roadmapContents.map((roadmapContent, index) => {
                  if (0 === index) {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                      >
                        <Box>
                          <CardMedia title="background" image={PercentBG}>
                            <Typography
                              component={"div"}
                              style={{
                                height: "140px",
                                width: "140px",
                                lineHeight: "74px",
                                color: "#FFF",
                                fontWeight: 700,
                                fontSize: "21px",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 50,
                                  lineHeight: "140px",
                                  color: "transparent",
                                }}
                              >
                                %
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 70,
                                  lineHeight: "140px",
                                }}
                              >
                                {roadmapContent.percentage}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 25,
                                  lineHeight: "140px",
                                }}
                              >
                                %
                              </Typography>
                            </Typography>
                          </CardMedia>
                        </Box>
                        <Box style={{ paddingLeft: 50 }}>
                          <Typography component={"div"}>
                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 21,
                                lineHeight: "40px",
                                color: "#FFF",
                              }}
                            >
                              {roadmapContent.title}
                            </Typography>

                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "30px",
                                color: "#bfbfbf",
                              }}
                            >
                              {roadmapContent.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                  if (4 === index) {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                      >
                        <Box>
                          <CardMedia title="background" image={PercentBG}>
                            <Typography
                              component={"div"}
                              style={{
                                height: "140px",
                                width: "140px",
                                lineHeight: "74px",
                                color: "#FFF",
                                fontWeight: 700,
                                fontSize: "21px",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 10,
                                  lineHeight: "140px",
                                  color: "transparent",
                                }}
                              >
                                %
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 60,
                                  lineHeight: "140px",
                                }}
                              >
                                {roadmapContent.percentage}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 25,
                                  lineHeight: "140px",
                                }}
                              >
                                %
                              </Typography>
                            </Typography>
                          </CardMedia>
                        </Box>
                        <Box style={{ paddingLeft: 50 }}>
                          <Typography component={"div"}>
                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 21,
                                lineHeight: "40px",
                                color: "#FFF",
                              }}
                            >
                              {roadmapContent.title}
                            </Typography>

                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "30px",
                                color: "#bfbfbf",
                              }}
                            >
                              {roadmapContent.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                  return (
                    <Stack direction={"row"} alignItems={"center"} key={index}>
                      <Box>
                        <CardMedia title="background" image={PercentBG}>
                          <Typography
                            component={"div"}
                            style={{
                              height: "140px",
                              width: "140px",
                              lineHeight: "74px",
                              color: "#FFF",
                              fontWeight: 700,
                              fontSize: "21px",
                            }}
                          >
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 25,
                                lineHeight: "140px",
                                color: "transparent",
                              }}
                            >
                              %
                            </Typography>
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 70,
                                lineHeight: "140px",
                              }}
                            >
                              {roadmapContent.percentage}
                            </Typography>
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 25,
                                lineHeight: "140px",
                              }}
                            >
                              %
                            </Typography>
                          </Typography>
                        </CardMedia>
                      </Box>
                      <Box style={{ paddingLeft: 50 }}>
                        <Typography component={"div"}>
                          <Typography
                            component={"div"}
                            style={{
                              fontSize: 21,
                              lineHeight: "40px",
                              color: "#FFF",
                            }}
                          >
                            {roadmapContent.title}
                          </Typography>

                          <Typography
                            component={"div"}
                            style={{
                              fontSize: 19,
                              lineHeight: "30px",
                              color: "#bfbfbf",
                            }}
                          >
                            {roadmapContent.description}
                          </Typography>
                        </Typography>
                      </Box>
                    </Stack>
                  )
                })}

                <IconButton
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    marginTop: "80px",
                    marginBottom: "80px",
                  }}
                  // onClick={onComing}
                >
                  <CardMedia title="background" image={MintButtonIcon}>
                    <Button
                      href="https://twitter.com/BaseApeGame"
                      style={{
                        height: "74px",
                        width: "350px",
                        lineHeight: "74px",
                        color: "#FFF",
                        fontWeight: 700,
                        fontSize: "21px",
                      }}
                    >
                      JOIN US
                    </Button>
                  </CardMedia>
                </IconButton>
              </Stack>
            </div>
            <Hidden mdUp>
              <div className={classes["m-nft-images-container"]}>
                <Stack direction={"column"} spacing={3}>
                  {roadmapContents.map((roadmapContent, index) => {
                    if (0 === index) {
                      return (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          key={index}
                        >
                          <Box>
                            <CardMedia title="background" image={PercentBG}>
                              <Typography
                                component={"div"}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  lineHeight: "80px",
                                  color: "#FFF",
                                  fontWeight: 700,
                                  fontSize: "21px",
                                }}
                              >
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 30,
                                    lineHeight: "100px",
                                    color: "transparent",
                                  }}
                                >
                                  %
                                </Typography>
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 40,
                                    lineHeight: "100px",
                                  }}
                                >
                                  {roadmapContent.percentage}
                                </Typography>
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 23,
                                    lineHeight: "100px",
                                  }}
                                >
                                  %
                                </Typography>
                              </Typography>
                            </CardMedia>
                          </Box>
                          <Box style={{ paddingLeft: 20 }}>
                            <Typography component={"div"}>
                              <Typography
                                component={"div"}
                                style={{
                                  fontSize: 19,
                                  lineHeight: "25px",
                                  color: "#FFF",
                                }}
                              >
                                {roadmapContent.title}
                              </Typography>

                              <Typography
                                component={"div"}
                                style={{
                                  fontSize: 19,
                                  lineHeight: "25px",
                                  color: "#bfbfbf",
                                }}
                              >
                                {roadmapContent.description}
                              </Typography>
                            </Typography>
                          </Box>
                        </Stack>
                      )
                    }
                    if (4 === index) {
                      return (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          key={index}
                        >
                          <Box>
                            <CardMedia title="background" image={PercentBG}>
                              <Typography
                                component={"div"}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  lineHeight: "80px",
                                  color: "#FFF",
                                  fontWeight: 700,
                                  fontSize: "21px",
                                }}
                              >
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 8,
                                    lineHeight: "100px",
                                    color: "transparent",
                                  }}
                                >
                                  %
                                </Typography>
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 40,
                                    lineHeight: "100px",
                                  }}
                                >
                                  {roadmapContent.percentage}
                                </Typography>
                                <Typography
                                  component={"span"}
                                  style={{
                                    fontSize: 23,
                                    lineHeight: "100px",
                                  }}
                                >
                                  %
                                </Typography>
                              </Typography>
                            </CardMedia>
                          </Box>
                          <Box style={{ paddingLeft: 20 }}>
                            <Typography component={"div"}>
                              <Typography
                                component={"div"}
                                style={{
                                  fontSize: 19,
                                  lineHeight: "25px",
                                  color: "#FFF",
                                }}
                              >
                                {roadmapContent.title}
                              </Typography>

                              <Typography
                                component={"div"}
                                style={{
                                  fontSize: 19,
                                  lineHeight: "25px",
                                  color: "#bfbfbf",
                                }}
                              >
                                {roadmapContent.description}
                              </Typography>
                            </Typography>
                          </Box>
                        </Stack>
                      )
                    }
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                      >
                        <Box>
                          <CardMedia title="background" image={PercentBG}>
                            <Typography
                              component={"div"}
                              style={{
                                height: "100px",
                                width: "100px",
                                lineHeight: "80px",
                                color: "#FFF",
                                fontWeight: 700,
                                fontSize: "21px",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 23,
                                  lineHeight: "100px",
                                  color: "transparent",
                                }}
                              >
                                %
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 40,
                                  lineHeight: "100px",
                                }}
                              >
                                {roadmapContent.percentage}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 23,
                                  lineHeight: "100px",
                                }}
                              >
                                %
                              </Typography>
                            </Typography>
                          </CardMedia>
                        </Box>
                        <Box style={{ paddingLeft: 20 }}>
                          <Typography component={"div"}>
                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#FFF",
                              }}
                            >
                              {roadmapContent.title}
                            </Typography>

                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#bfbfbf",
                              }}
                            >
                              {roadmapContent.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  })}

                  <IconButton
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 0,
                      marginTop: "80px",
                      marginBottom: "80px",
                    }}
                    // onClick={onComing}
                  >
                    <CardMedia title="background" image={MintButtonIcon}>
                      <Button
                        href="https://twitter.com/BaseApeGame"
                        style={{
                          height: "74px",
                          width: "350px",
                          lineHeight: "74px",
                          color: "#FFF",
                          fontWeight: 700,
                          fontSize: "21px",
                        }}
                      >
                        JOIN US
                      </Button>
                    </CardMedia>
                  </IconButton>
                  <Typography
                    component={"div"}
                    style={{
                      fontSize: 19,
                      lineHeight: "30px",
                      color: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    COME BLAZE WITH US.
                  </Typography>
                  <Typography
                    component={"div"}
                    href="https://twitter.com/BaseApeGame"
                    style={{
                      fontSize: 21,
                      lineHeight: "40px",
                      color: "#FFF",
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    Follow us
                  </Typography>
                </Stack>
              </div>
            </Hidden>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes["m-introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                id="roadmap"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                RoadMap
              </Typography>
            </div>
            <div className={classes["nft-images-container"]}>
              <Stack direction={"column"} spacing={3}>
                {roadmapContents.map((roadmapContent, index) => {
                  if (0 === index) {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                      >
                        <Box>
                          <CardMedia title="background" image={PercentBG}>
                            <Typography
                              component={"div"}
                              style={{
                                height: "100px",
                                width: "100px",
                                lineHeight: "80px",
                                color: "#FFF",
                                fontWeight: 700,
                                fontSize: "21px",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 30,
                                  lineHeight: "100px",
                                  color: "transparent",
                                }}
                              >
                                %
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 40,
                                  lineHeight: "100px",
                                }}
                              >
                                {roadmapContent.percentage}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 23,
                                  lineHeight: "100px",
                                }}
                              >
                                %
                              </Typography>
                            </Typography>
                          </CardMedia>
                        </Box>
                        <Box style={{ paddingLeft: 20 }}>
                          <Typography component={"div"}>
                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#FFF",
                              }}
                            >
                              {roadmapContent.title}
                            </Typography>

                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#bfbfbf",
                              }}
                            >
                              {roadmapContent.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                  if (4 === index) {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                      >
                        <Box>
                          <CardMedia title="background" image={PercentBG}>
                            <Typography
                              component={"div"}
                              style={{
                                height: "100px",
                                width: "100px",
                                lineHeight: "80px",
                                color: "#FFF",
                                fontWeight: 700,
                                fontSize: "21px",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 8,
                                  lineHeight: "100px",
                                  color: "transparent",
                                }}
                              >
                                %
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 40,
                                  lineHeight: "100px",
                                }}
                              >
                                {roadmapContent.percentage}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize: 23,
                                  lineHeight: "100px",
                                }}
                              >
                                %
                              </Typography>
                            </Typography>
                          </CardMedia>
                        </Box>
                        <Box style={{ paddingLeft: 20 }}>
                          <Typography component={"div"}>
                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#FFF",
                              }}
                            >
                              {roadmapContent.title}
                            </Typography>

                            <Typography
                              component={"div"}
                              style={{
                                fontSize: 19,
                                lineHeight: "25px",
                                color: "#bfbfbf",
                              }}
                            >
                              {roadmapContent.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                  return (
                    <Stack direction={"row"} alignItems={"center"} key={index}>
                      <Box>
                        <CardMedia title="background" image={PercentBG}>
                          <Typography
                            component={"div"}
                            style={{
                              height: "100px",
                              width: "100px",
                              lineHeight: "80px",
                              color: "#FFF",
                              fontWeight: 700,
                              fontSize: "21px",
                            }}
                          >
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 23,
                                lineHeight: "100px",
                                color: "transparent",
                              }}
                            >
                              %
                            </Typography>
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 40,
                                lineHeight: "100px",
                              }}
                            >
                              {roadmapContent.percentage}
                            </Typography>
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: 23,
                                lineHeight: "100px",
                              }}
                            >
                              %
                            </Typography>
                          </Typography>
                        </CardMedia>
                      </Box>
                      <Box style={{ paddingLeft: 20 }}>
                        <Typography component={"div"}>
                          <Typography
                            component={"div"}
                            style={{
                              fontSize: 19,
                              lineHeight: "25px",
                              color: "#FFF",
                            }}
                          >
                            {roadmapContent.title}
                          </Typography>

                          <Typography
                            component={"div"}
                            style={{
                              fontSize: 19,
                              lineHeight: "25px",
                              color: "#bfbfbf",
                            }}
                          >
                            {roadmapContent.description}
                          </Typography>
                        </Typography>
                      </Box>
                    </Stack>
                  )
                })}

                <IconButton
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    marginTop: "50px",
                    marginBottom: "30px",
                  }}
                  // onClick={onComing}
                >
                  <CardMedia
                    title="background"
                    image={MintButtonIcon}
                    style={{ backgroundSize: "100% 100%" }}
                  >
                    <Button
                      href="https://twitter.com/BaseApeGame"
                      style={{
                        height: "60px",
                        width: "250px",
                        lineHeight: "60px",
                        color: "#FFF",
                        fontWeight: 700,
                        fontSize: "21px",
                      }}
                    >
                      JOIN US
                    </Button>
                  </CardMedia>
                </IconButton>
              </Stack>
            </div>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
