import React from "react";
import { CardMedia, Hidden, Typography, Box } from "@mui/material";
import classes from "./HeadBanner.module.scss";
import HeadBannerImg from "../../assets/images/headbanner/banner.png";
import { Countdown } from "..";

export const HeadBanner = () => {
  const onComing = () => {
    console.log("on coming");
  };

  const onStart = () => {
    console.log("on start");
  };

  return (
    <div>
      <Hidden mdDown>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={classes["banner-wrapper"]}>
            <CardMedia
              component={"img"}
              src={HeadBannerImg}
              style={{
                width: 1280,
                borderBottom: "3px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to left, #018a4b, #15d45f, #15d45f, #018a4b) 1",
              }}
            />
            <Box className={classes["mint-apes"]}>
              <Typography component="div">
                <Box className={classes["mint-apes-button"]}>
                  <Countdown onComing={onComing} onStart={onStart} />
                </Box>
              </Typography>
            </Box>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={classes["m-banner-wrapper"]}>
            <CardMedia
              component={"img"}
              src={HeadBannerImg}
              style={{
                borderBottom: "2px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to left, #018a4b, #15d45f, #15d45f, #018a4b) 1",
              }}
            />
            <Box className={classes["mint-apes"]}>
              <Typography component="div">
                <Box className={classes["mint-apes-button"]}>
                  <Countdown onComing={onComing} onStart={onStart} />
                </Box>
              </Typography>
            </Box>
          </div>
        </div>
      </Hidden>
    </div>
  );
};
