import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, CardMedia } from "@mui/material";
import MintProgressBG from "../../assets/images/mint/mint_progress.png";
import MintProgressBar from "../../assets/images/mint/mint_progressbar.png";

export const MintProgress = ({ now, total }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(Math.floor((now / total) * 100));
    return () => {};
  }, [now, total]);
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "14px",
        }}
      >
        <BorderLinearProgress variant="determinate" value={progress} />

        <div
          alt="mintProgress"
          src={MintProgressBG}
          style={{
            position: "absolute",
            width: "100%",
            height: "36px",
            backgroundImage: `url(${MintProgressBG})`,
            top: "-5px",
            backgroundSize: "100% 100%",
            left: -(100 - progress) + "%",
          }}
        />
      </div>
      <div>
        {progress >= 70 && (
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              top: "-25px",
              right: 0,
              color: "#F3CD72",
            }}
          >
            {now}/{total}{" "}
          </span>
        )}
        {progress < 70 && (
          <span
            style={{
              position: "absolute",
              display: "inline-block",
              left: progress + "%",
              top: 5,
              marginLeft: 20,
              color: "#F3CD72",
            }}
          >
            {now}/{total}{" "}
          </span>
        )}
      </div>
      <div>
        {progress <= 2 && (
          <CardMedia
            alt="mintProgress"
            component={"img"}
            image={MintProgressBar}
            style={{
              position: "absolute",
              width: "50px",
              top: "-10px",
              marginLeft: "-35px",
              left: "10px",
            }}
          />
        )}
        {progress > 2 && (
          <CardMedia
            alt="mintProgress"
            component={"img"}
            image={MintProgressBar}
            style={{
              position: "absolute",
              width: "50px",
              top: "-10px",
              marginLeft: "-35px",
              left: progress + "%",
            }}
          />
        )}
      </div>
    </Box>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 28,
  borderRadius: 14,
  border: "1px solid #00ffff",
  //   borderImage: "linear-gradient(to right, #0278c0, #00ffff) 1",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#000",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#000",
  },
}));
