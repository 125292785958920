import {
  Button,
  CardMedia,
  Hidden,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React, { useState, useEffect } from "react";
import { claim, getStakedNFTSWithAccount, rescue } from "../../client/mine";
import "./Mine.scss";
import { StakeCharacterCard } from "./StakeCharacterCard";
import { toast } from "react-toastify";
import MintButtonIcon from "../../assets/images/headbanner/mint_btn.png";
import {
  ContractErrorMsgs,
  ContractErrors,
  jsonBaseUrl,
} from "../../constants";
import { getTokenAvalable, getTokenURI } from "../../client/erc721";
import { getStakedDetails } from "../../client/mine";

import LoadingAnime from "../../assets/images/loading.svg";
import axios from "axios";

export const Stake = (props) => {
  const dailyIncome = 500000000;
  const { setIndex } = props;
  const [characters, setCharacters] = useState([]);
  const [page, setPage] = useState(1);
  const [claiming, setClaiming] = useState(false);
  const [rescuing, setRescuing] = useState(false);
  const [selectedRescueIDs, setSelectedRescueIDs] = useState([]);
  const [canBeSelected, setCanBeSelected] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const charactersPerPage = 10;

  const handleChange = (e, v) => {
    setPage(v);
  };

  const { account, library } = useWeb3React();
  useEffect(() => {
    if (account && library) {
      // TODO: fix this
      asyncGetMyStakedNFTS(account);
    }
    return () => {};
  }, [account, library]);

  const asyncGetMyStakedNFTS = async (account) => {
    if (!account) {
      toast.info("please connect to your wallet first");
      return;
    }

    try {
      setIsLoading(true);
      const nfts = await getStakedNFTSWithAccount(account);
      console.log("nfts", nfts);
      if (nfts && nfts.length > 0) {
        // a character should have (tokenId, attack, defense, speed)
        const chars = [];
        // each time 10 characters query contract
        const nftStakeDetails = await getStakedDetails(nfts);
        console.log("nftStakeDetails", nftStakeDetails);
        const isAvaliable = await getTokenAvalable();
        console.log(isAvaliable);
        if (isAvaliable) {
          for (const [index, tokenId] of nfts.entries()) {
            // get info from json
            let result = await axios.get(
              jsonBaseUrl + "metadata/" + tokenId.toString() + ".json"
            );
            if (
              result.status === 200 &&
              result.data &&
              result.data.attributes
            ) {
              const jsonInfo = result.data;
              chars.push({
                tokenId: tokenId,
                isOpen: true,
                isApe: jsonInfo.attributes[5] !== "Human",
                attack: jsonInfo.attributes[6].value,
                defense: jsonInfo.attributes[7].value,
                speed: jsonInfo.attributes[8].value,
                imageUrl: jsonBaseUrl + "nfts/" + tokenId.toString() + ".png",
                ipfsUrl: jsonInfo.image,
                value: nftStakeDetails[index].value, // last stake timestamp
              });
            }
          }
        } else {
          for (const [index, tokenId] of nfts.entries()) {
            chars.push({
              value: nftStakeDetails[index].value, // last stake timestamp
              tokenId: tokenId,
              isOpen: false,
              isApe: true,
              attack: 0,
              defense: 0,
              speed: 0,
              imageUrl: "",
              ipfsUrl: "",
            });
          }
        }

        setIsLoading(false);
        setCharacters(chars);
      } else {
        setIsLoading(false);
        setCharacters([]);
        console.log("setCharacters");
      }
    } catch (e) {
      // TODO: toast
      setIsLoading(false);
      console.log("error", e);
      setCharacters([]);
    }
  };

  const setRescueID = (rescueID) => {
    if (rescueID) {
      const newCharacters = characters.filter((c) => {
        return c.tokenId.toString() !== rescueID.toString();
      });
      setCharacters(newCharacters);
    }
  };

  const goToMine = () => {
    setIndex(0);
  };

  const addToSelectedRescueIDs = (rescueId) => {
    setSelectedRescueIDs((prev) => {
      if (prev.length === 4) {
        setCanBeSelected(false);
      }
      const newIDs = new Set(prev);
      newIDs.add(rescueId);
      return Array.from(newIDs);
    });
  };

  const removeFromSelectedRescueIDs = (rescueId) => {
    setSelectedRescueIDs((prev) => {
      if (prev.length === 5) {
        setCanBeSelected(true);
      }
      const newIDs = new Set(prev);
      newIDs.delete(rescueId);
      return Array.from(newIDs);
    });
  };

  const claimSelected = () => {
    if (selectedRescueIDs.length === 0) {
      toast.warn("no warrior selected!");
      return;
    }
    setClaiming(true);
    toast.promise(claim(selectedRescueIDs), {
      pending: "claiming warrior now...",
      success: {
        render: (resp) => {
          setClaiming(false);
          const claimResp = resp.data;
          if (claimResp.success && claimResp.survivors) {
            setSelectedRescueIDs([]);
            for (let i = 0; i < claimResp.survivors.length; i++) {
              const survivor = claimResp.survivors[i];
              if (i === claimResp.survivors.length - 1) {
                return `You have successfully claimed your ${
                  survivor.isApe ? "ape" : "human"
                } warrior:${survivor.survivorID.toString()}`;
              }
              toast.success(
                `You have successfully claimed your ${
                  survivor.isApe ? "ape" : "human"
                } warrior:${survivor.survivorID.toString()}`
              );
            }
          }
          return `Claim warrior failed!`;
        },
      },
      error: {
        render: (err) => {
          setClaiming(false);
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message ===
              ContractErrors.MineCantUnStakeBeforeStakedForDays
            ) {
              return ContractErrorMsgs.MineCantUnStakeBeforeStakedForDaysMsg;
            } else if (
              err.data.data.message ===
              ContractErrors.ERC20TransferBalanceNotEnough
            ) {
              return ContractErrorMsgs.ERC20TransferBalanceNotEnoughMsg;
            }
            console.log(err.data.data.message);
            return err.data.data.message;
          }
          return "Claim warrior failed!";
        },
      },
    });
  };

  const rescueSelected = () => {
    if (selectedRescueIDs.length === 0) {
      toast.warn("no warrior selected!");
      return;
    }
    setRescuing(true);
    toast.promise(rescue(selectedRescueIDs), {
      pending: "Rescuing warriors now...",
      success: {
        render: (resp) => {
          setRescuing(false);
          const claimResp = resp.data;
          if (claimResp.success && claimResp.survivors) {
            setCharacters((prev) => {
              const selectedIDs = new Set(selectedRescueIDs);
              const newCharacters = prev.filter((c) => {
                return !selectedIDs.has(c.tokenId);
              });
              setSelectedRescueIDs([]);
              return newCharacters;
            });
            for (let i = 0; i < claimResp.survivors.length; i++) {
              const survivor = claimResp.survivors[i];
              if (i === claimResp.survivors.length - 1) {
                return `You have successfully rescued your ${
                  survivor.isApe ? "ape" : "human"
                } warrior:${survivor.survivorID.toString()}`;
              }
              toast.success(
                `You have successfully rescued your ${
                  survivor.isApe ? "ape" : "human"
                } warrior:${survivor.survivorID.toString()}`
              );
            }
          }
          console.log(resp);
          return `Rescue warriors failed!`;
        },
      },
      error: {
        render: (err) => {
          setRescuing(false);
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message ===
              ContractErrors.MineCantUnStakeBeforeStakedForDays
            ) {
              return ContractErrorMsgs.MineCantUnStakeBeforeStakedForDaysMsg;
            }
            console.log(err.data.data.message);
            return err.data.data.message;
          }
          return "Rescue warriors failed!";
        },
      },
    });
  };

  return (
    <div>
      <Hidden mdDown>
        <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"left"}>
          {characters
            .slice(
              (page - 1) * charactersPerPage,
              page * charactersPerPage > characters.length
                ? characters.length
                : page * charactersPerPage
            )
            .map((c, index) => (
              <StakeCharacterCard
                key={index}
                character={c}
                dailyIncome={dailyIncome}
                setRescueID={setRescueID}
                setSelectedRescueID={addToSelectedRescueIDs}
                deSelectRescueID={removeFromSelectedRescueIDs}
                shouldClaiming={
                  claiming && new Set(selectedRescueIDs).has(c.tokenId)
                }
                shouldRescuing={
                  rescuing && new Set(selectedRescueIDs).has(c.tokenId)
                }
                canBeSelected={canBeSelected}
              />
            ))}
          {characters.length === 0 && isLoading && (
            <Stack
              style={{ height: "60vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CardMedia
                component={"img"}
                src={LoadingAnime}
                style={{ width: 64 }}
              />
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                  fontSize: "28px",
                }}
              >
                loading
              </Typography>
            </Stack>
          )}
          {characters.length === 0 && !isLoading && (
            <Stack
              style={{ height: "50vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                }}
              >
                no characters staked now!
              </Typography>
              <Button
                onClick={goToMine}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  background: "linear-gradient(to right, #018a4b, #1beb65)",
                  fontSize: "20px",
                  fontWeight: 700,
                  width: "200px",
                  borderRadius: "10px",
                }}
              >
                stake more
              </Button>
            </Stack>
          )}
        </Stack>
      </Hidden>
      <Hidden mdUp>
        <Stack
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {characters
            .slice(
              (page - 1) * charactersPerPage,
              page * charactersPerPage > characters.length
                ? characters.length
                : page * charactersPerPage
            )
            .map((c, index) => (
              <StakeCharacterCard
                key={index}
                character={c}
                dailyIncome={dailyIncome}
                setRescueID={setRescueID}
                setSelectedRescueID={addToSelectedRescueIDs}
                deSelectRescueID={removeFromSelectedRescueIDs}
                shouldClaiming={
                  claiming && new Set(selectedRescueIDs).has(c.tokenId)
                }
                shouldRescuing={
                  rescuing && new Set(selectedRescueIDs).has(c.tokenId)
                }
                canBeSelected={canBeSelected}
              />
            ))}

          {characters.length === 0 && isLoading && (
            <Stack
              style={{ height: "60vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CardMedia
                component={"img"}
                src={LoadingAnime}
                style={{ width: 64 }}
              />
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                  fontSize: "28px",
                }}
              >
                loading
              </Typography>
            </Stack>
          )}
          {characters.length === 0 && !isLoading && (
            <Stack
              style={{ height: "50vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                }}
              >
                no characters staked now!
              </Typography>
              <Button
                onClick={goToMine}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  background: "linear-gradient(to right, #018a4b, #1beb65)",
                  fontSize: "20px",
                  fontWeight: 700,
                  width: "200px",
                  borderRadius: "10px",
                }}
              >
                stake more
              </Button>
            </Stack>
          )}
        </Stack>
      </Hidden>
      <Stack spacing={1} justifyContent={"center"} direction={"row"}>
        {characters.length > 0 && (
          <IconButton
            style={{
              borderRadius: 0,
              marginTop: "80px",
              marginBottom: "80px",
            }}
            onClick={claimSelected}
          >
            <CardMedia
              title="background"
              image={MintButtonIcon}
              style={{ backgroundSize: "100% 100%" }}
            >
              <Typography
                style={{
                  height: "50px",
                  lineHeight: "50px",
                  width: "180px",
                  color: "#FFF",
                  fontWeight: 700,
                  fontSize: "17px",
                  textTransform: "uppercase",
                }}
              >
                {claiming ? "Claiming..." : "Claim Selected"}
              </Typography>
            </CardMedia>
          </IconButton>
        )}
        {characters.length > 0 && (
          <IconButton
            style={{
              borderRadius: 0,
              marginTop: "80px",
              marginBottom: "80px",
            }}
            onClick={rescueSelected}
          >
            <CardMedia
              title="background"
              image={MintButtonIcon}
              style={{ backgroundSize: "100% 100%" }}
            >
              <Typography
                style={{
                  height: "50px",
                  lineHeight: "50px",
                  width: "180px",
                  color: "#FFF",
                  fontWeight: 700,
                  fontSize: "17px",
                  textTransform: "uppercase",
                }}
              >
                {rescuing ? "Rescuing..." : "Rescue Selected"}
              </Typography>
            </CardMedia>
          </IconButton>
        )}
      </Stack>
      {characters.length > 0 && (
        <Stack spacing={2} alignItems={"center"}>
          <Pagination
            page={page}
            onChange={handleChange}
            size="large"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(characters.length / charactersPerPage)}
          />
        </Stack>
      )}
    </div>
  );
};
