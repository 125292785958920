import { Tab, Tabs, Typography, Hidden, CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Mine } from "./Mine";
import classes from "./MyNft.module.scss";
import { Stake } from "./Stake";
import SectionTitleBG from "../../assets/images/section/section_title_bg.png";
import TabBtnImg1 from "../../assets/images/game/tab_btn1.png";
import TabBtnImg2 from "../../assets/images/game/tab_btn2.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const MyNft = () => {
  const [index, setIndex] = useState(0);

  const handleChange = (event, newIndex) => {
    setIndex(newIndex);
  };
  return (
    <div>
      <Hidden mdDown>
        <div className={classes.mynft}>
          <div className={classes["title-container"]}>
            <CardMedia
              component={"img"}
              src={SectionTitleBG}
              style={{
                width: 185,
              }}
            />
            <Typography
              style={{
                backgroundImage: "linear-gradient(to bottom, #1bec65, #018a4b)",
                WebkitBackgroundClip: "text",
                color: "transparent",
                textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                position: "absolute",
                width: 130,
                textAlign: "center",
                top: -30,
                fontSize: 23,
                letterSpacing: 2,
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            >
              GAME
            </Typography>
          </div>
          <Box>
            <Tabs
              value={index}
              onChange={handleChange}
              indicatorColor="transparent"
              style={{
                borderBottom: "5px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to bottom,#018a4b, #018a4b, #018a4b) 1",
                paddingLeft: 150,
              }}
            >
              <Tab
                label="My NFT"
                style={{
                  color: "#FFF",
                  fontSize: "19px",
                  fontWeight: 700,
                  backgroundImage:
                    index === 1 ? `url(${TabBtnImg1})` : `url(${TabBtnImg2})`,
                  backgroundSize: "100% 100%",
                  padding: "0 30px",
                }}
              />
              <Tab
                label="STAKING"
                style={{
                  marginLeft: 10,
                  color: "#FFF",
                  fontSize: "19px",
                  fontWeight: 700,
                  backgroundImage:
                    index === 1 ? `url(${TabBtnImg2})` : `url(${TabBtnImg1})`,
                  backgroundSize: "100% 100%",
                  padding: "0 20px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={index}
            index={0}
            children={<Mine setIndex={setIndex} />}
          />
          <TabPanel
            value={index}
            index={1}
            children={<Stake setIndex={setIndex} />}
          />
        </div>
      </Hidden>

      <Hidden mdUp>
        <div className={classes["m-mynft"]}>
          <div className={classes["m-title-container"]}>
            <CardMedia
              component={"img"}
              src={SectionTitleBG}
              style={{
                width: 185,
              }}
            />
            <Typography
              style={{
                backgroundImage: "linear-gradient(to bottom, #1bec65, #018a4b)",
                WebkitBackgroundClip: "text",
                color: "transparent",
                textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                position: "absolute",
                width: 130,
                textAlign: "center",
                top: -30,
                fontSize: 23,
                letterSpacing: 2,
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            >
              GAME
            </Typography>
          </div>
          <Box>
            <Tabs
              value={index}
              onChange={handleChange}
              indicatorColor="transparent"
              style={{
                borderBottom: "5px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to bottom,#018a4b, #018a4b, #018a4b) 1",
                paddingLeft: 50,
              }}
            >
              <Tab
                label="My NFT"
                style={{
                  color: "#FFF",
                  fontSize: "19px",
                  fontWeight: 700,
                  backgroundImage:
                    index === 1 ? `url(${TabBtnImg1})` : `url(${TabBtnImg2})`,
                  backgroundSize: "100% 100%",
                  padding: "0 30px",
                }}
              />
              <Tab
                label="STAKING"
                style={{
                  marginLeft: 10,
                  color: "#FFF",
                  fontSize: "19px",
                  fontWeight: 700,
                  backgroundImage:
                    index === 1 ? `url(${TabBtnImg2})` : `url(${TabBtnImg1})`,
                  backgroundSize: "100% 100%",
                  padding: "0 20px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={index} index={0} children={<Mine />} />
          <TabPanel value={index} index={1} children={<Stake />} />
        </div>
      </Hidden>
    </div>
  );
};
