import React from "react";
import classes from "./NFT.module.scss";

import { Header, Footer, Mint } from "../../components";

const NFTPage = () => {
  return (
    <div className={classes.nft}>
      {/* header */}
      <Header />
      {/* mint */}
      <Mint />
      {/* footer */}
      <Footer />
    </div>
  );
};

export default NFTPage;
