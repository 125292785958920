import {
  Button,
  CardMedia,
  Hidden,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material"
import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import MintButtonIcon from "../../assets/images/headbanner/mint_btn.png"
import LoadingAnime from "../../assets/images/loading.svg"
import { getMyNFTs, getTokenAvalable } from "../../client/erc721"
import { stake } from "../../client/mine"
import { ContractErrorMsgs, ContractErrors, jsonBaseUrl } from "../../constants"
import "./Mine.scss"
import { MineCharacterCard } from "./MineCharacterCard"

export const Mine = (props) => {
  // const { setIndex } = props;
  const [characters, setCharacters] = useState([])
  const [page, setPage] = useState(1)
  const [selectedStakeIDs, setSelectedStakeIDs] = useState([])
  const [staking, setStaking] = useState(false)
  const [canBeSelected, setCanBeSelected] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const charactersPerPage = 10

  const handleChange = (e, v) => {
    setPage(v)
  }

  const setStakeID = (stakeId) => {
    if (stakeId) {
      const newCharacters = characters.filter((c) => {
        return c.tokenId.toString() !== stakeId.toString()
      })
      setCharacters(newCharacters)
    }
  }

  const addToSelectedStakeIDs = (stakeId) => {
    setSelectedStakeIDs((prev) => {
      if (prev.length === 4) {
        setCanBeSelected(false)
      }
      const newIDs = new Set(prev)
      newIDs.add(stakeId)
      return Array.from(newIDs)
    })
  }

  const removeFromSelectedStakeIDs = (stakeId) => {
    setSelectedStakeIDs((prev) => {
      if (prev.length === 5) {
        setCanBeSelected(true)
      }
      const newIDs = new Set(prev)
      newIDs.delete(stakeId)
      return Array.from(newIDs)
    })
  }

  const stakeSelected = () => {
    if (selectedStakeIDs.length === 0) {
      toast.warn("no warriors selected!")
      return
    }
    setStaking(true)
    toast.promise(stake(account, selectedStakeIDs), {
      pending: "Staking warriors now...",
      success: {
        render: (resp) => {
          setStaking(false)
          const mintResp = resp.data
          if (mintResp.success && mintResp.survivorIDs) {
            setCharacters((prev) => {
              const selectedIDs = new Set(selectedStakeIDs)
              const newCharacters = prev.filter((c) => {
                return !selectedIDs.has(c.tokenId)
              })
              setSelectedStakeIDs([])
              return newCharacters
            })
            for (let i = 0; i < mintResp.survivorIDs.length; i++) {
              const survivor = mintResp.survivorIDs[i]
              if (i === mintResp.survivorIDs.length - 1) {
                return `You have successfully staked your warrior:${survivor.toString()}`
              }
              toast.success(
                `You have successfully staked your warrior:${survivor.toString()}`
              )
            }
          }
          return `Stake warriors failed!`
        },
      },
      error: {
        render: (err) => {
          setStaking(false)
          console.log(err)
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message === ContractErrors.ERC721CantStakedDirectly
            ) {
              return ContractErrorMsgs.ERC721CantStakedDirectlyMsg
            }
            return err.data.data.message
          }
          return "Stake warriors failed!"
        },
      },
    })
  }

  const { account, library } = useWeb3React()
  useEffect(() => {
    if (account && library) {
      const asyncGetMyNFTS = async () => {
        if (!account) {
          toast.info("please connect to your wallet first")
          return
        }
        try {
          setIsLoading(true)
          const nfts = await getMyNFTs(account)
          if (nfts && nfts.length > 0) {
            console.log(nfts)
            // a character should have (tokenId, attack, defense, speed)
            const chars = []
            const isAvaliable = await getTokenAvalable()
            console.log(isAvaliable)
            if (isAvaliable) {
              for (const tokenId of nfts) {
                // get info from json
                let result = await axios.get(
                  jsonBaseUrl + "metadata/" + tokenId.toString() + ".json"
                )
                if (
                  result.status === 200 &&
                  result.data &&
                  result.data.attributes
                ) {
                  const jsonInfo = result.data
                  chars.push({
                    tokenId: tokenId,
                    isOpen: true,
                    isApe: jsonInfo.attributes[5] !== "Human",
                    attack: jsonInfo.attributes[6].value,
                    defense: jsonInfo.attributes[7].value,
                    speed: jsonInfo.attributes[8].value,
                    imageUrl:
                      jsonBaseUrl + "nfts/" + tokenId.toString() + ".png",
                    ipfsUrl: jsonInfo.image,
                  })
                }
              }
            } else {
              for (const tokenId of nfts) {
                chars.push({
                  tokenId: tokenId,
                  isOpen: false,
                  isApe: true,
                  attack: 0,
                  defense: 0,
                  speed: 0,
                  imageUrl: "",
                  ipfsUrl: "",
                })
              }
              console.log(chars)
            }
            setIsLoading(false)
            setCharacters(chars)
          } else {
            setIsLoading(false)
            setCharacters([])
          }
        } catch (e) {
          setIsLoading(false)
          console.log(e)
          toast.error("list warriors error!")
          setCharacters([])
        }
      }
      asyncGetMyNFTS()
    }
  }, [account, library])

  let navigate = useNavigate()
  const getMoreSurvivor = () => {
    navigate("/nft")
  }

  return (
    <div>
      <Hidden mdUp>
        <Stack
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {characters
            .slice(
              (page - 1) * charactersPerPage,
              page * charactersPerPage > characters.length
                ? characters.length
                : page * charactersPerPage
            )
            .map((c, index) => (
              <MineCharacterCard
                key={index}
                character={c}
                setStakeID={setStakeID}
                setSelectedStakeID={addToSelectedStakeIDs}
                deSelectStakeID={removeFromSelectedStakeIDs}
                shouldStaking={
                  staking && new Set(selectedStakeIDs).has(c.tokenId)
                }
                canBeSelected={canBeSelected}
              />
            ))}
          {characters.length === 0 && isLoading && (
            <Stack
              style={{ height: "50vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CardMedia
                component={"img"}
                src={LoadingAnime}
                style={{ width: 64 }}
              />
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                  fontSize: "28px",
                }}
              >
                loading
              </Typography>
            </Stack>
          )}
          {characters.length === 0 && !isLoading && (
            <Stack
              style={{ height: "50vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                }}
              >
                No characters now!
              </Typography>
              <Button
                onClick={getMoreSurvivor}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  background: "linear-gradient(to right, #018a4b, #1beb65)",
                  fontSize: "20px",
                  fontWeight: 700,
                  width: "200px",
                  borderRadius: "10px",
                }}
              >
                get more
              </Button>
            </Stack>
          )}
        </Stack>
      </Hidden>
      <Hidden mdDown>
        <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"left"}>
          {characters
            .slice(
              (page - 1) * charactersPerPage,
              page * charactersPerPage > characters.length
                ? characters.length
                : page * charactersPerPage
            )
            .map((c, index) => (
              <MineCharacterCard
                key={index}
                character={c}
                setStakeID={setStakeID}
                setSelectedStakeID={addToSelectedStakeIDs}
                deSelectStakeID={removeFromSelectedStakeIDs}
                shouldStaking={
                  staking && new Set(selectedStakeIDs).has(c.tokenId)
                }
                canBeSelected={canBeSelected}
              />
            ))}
          {characters.length === 0 && isLoading && (
            <Stack
              style={{ height: "60vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CardMedia
                component={"img"}
                src={LoadingAnime}
                style={{ width: 64 }}
              />
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                  fontSize: "28px",
                }}
              >
                loading
              </Typography>
            </Stack>
          )}
          {characters.length === 0 && !isLoading && (
            <Stack
              style={{ height: "50vh", width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant={"h5"}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  padding: "20px 0",
                }}
              >
                No characters now!
              </Typography>
              <Button
                onClick={getMoreSurvivor}
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  background: "linear-gradient(to right, #018a4b, #1beb65)",
                  fontSize: "20px",
                  fontWeight: 700,
                  width: "200px",
                  borderRadius: "10px",
                }}
              >
                get more
              </Button>
            </Stack>
          )}
        </Stack>
      </Hidden>
      <Hidden mdDown>
        <Stack spacing={2} alignItems={"center"}>
          {characters.length > 0 && (
            <IconButton
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 0,
                marginTop: "80px",
                marginBottom: "80px",
              }}
              onClick={stakeSelected}
            >
              <CardMedia title="background" image={MintButtonIcon}>
                <Typography
                  style={{
                    height: "74px",
                    width: "350px",
                    lineHeight: "74px",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "29px",
                    textTransform: "uppercase",
                  }}
                >
                  {staking ? "Staking..." : "Stake Selected"}
                </Typography>
              </CardMedia>
            </IconButton>
          )}
        </Stack>
      </Hidden>
      <Hidden mdUp>
        <Stack spacing={2} alignItems={"center"}>
          {characters.length > 0 && (
            <IconButton
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 0,
                marginTop: "80px",
                marginBottom: "80px",
              }}
              onClick={stakeSelected}
            >
              <CardMedia
                title="background"
                image={MintButtonIcon}
                style={{ backgroundSize: "100% 100%" }}
              >
                <Typography
                  style={{
                    height: "60px",
                    lineHeight: "60px",
                    color: "#FFF",
                    width: "280px",
                    fontWeight: 700,
                    fontSize: "23px",
                    textTransform: "uppercase",
                  }}
                >
                  {staking ? "Staking..." : "Stake Selected"}
                </Typography>
              </CardMedia>
            </IconButton>
          )}
        </Stack>
      </Hidden>
      <Stack spacing={2} alignItems={"center"}>
        {characters.length > 0 && (
          <Pagination
            page={page}
            onChange={handleChange}
            size="large"
            variant="outlined"
            shape="rounded"
            count={Math.ceil(characters.length / charactersPerPage)}
          />
        )}
      </Stack>
    </div>
  )
}
