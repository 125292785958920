import React, { useState, useEffect } from "react";
import classes from "./Mint.module.scss";
import {
  Button,
  CardMedia,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import SectionTitleBG from "../../assets/images/section/section_title_bg.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BoxImg from "../../assets/images/mint/mint_box.png";
import MintBG from "../../assets/images/mint/mint_bg.png";
import NFTImage0 from "../../assets/images/mint/nft_image_0.png";
import NFTImage1 from "../../assets/images/mint/nft_image_1.png";
import NFTImage2 from "../../assets/images/mint/nft_image_2.png";
import NFTImage3 from "../../assets/images/mint/nft_image_3.png";
import NFTImage4 from "../../assets/images/mint/nft_image_4.png";
import NFTImage5 from "../../assets/images/mint/nft_image_5.png";
import NFTImage6 from "../../assets/images/mint/nft_image_6.png";
import NFTImage7 from "../../assets/images/mint/nft_image_7.png";

import LinkIcon from "../../assets/images/link.svg";
import MintTitleImage from "../../assets/images/mint/mint_title.png";
import { MintProgress } from "../mintprogress/MintProgress";
import {
  getPrice,
  getTotalFoundedSurvivors,
  getTotalSurvivors,
  mintNFT,
} from "../../client/erc721";
import { useWeb3React } from "@web3-react/core";
import { contracts } from "../../client/contracts";
import {
  ContractErrorMsgs,
  ContractErrors,
  openTime,
  contractBaseUrl,
} from "../../constants";
import { toast } from "react-toastify";
import { getTTBalance } from "../../client/erc20";
import { formatBigNumber } from "../../utils";
import { ethers } from "ethers";

const contents = {
  description: `
  Biochemical experiments and over-exploitation of natural resources continue to cause great damage to the ecological environment. When we threaten the planet, we are undermining our only home in the foreseeable future. This time, based on a virtual reality where the collapse of ecological balance has brought out an all-out war between human and mutated apes, creators present 10,000 NFT images of the apes and humans both affected by the environment to call on people today to pay continuous attention to environmental protection in the process of development.
  `,
};

export const Mint = () => {
  // address
  const nftContractAddress = contracts.nftContract;
  const tokenContractAddress = contracts.apesToken;
  
  const startTime = new Date(
    Date.UTC(
      openTime.year,
      openTime.month - 1,
      openTime.day,
      openTime.hour,
      openTime.minute,
      openTime.second
    )
  ).toUTCString();
  // price
  const [price, setPrice] = useState(0);
  // count
  const [count, setCount] = useState(1);
  // now
  const [now, setNow] = useState(0);
  // total
  const [total, setTotal] = useState(0);
  // ttAmount
  const [ttAmount, setTtAmount] = useState(0);
  // faucetAmount
  // const [faucetAmount, setFaucetAmount] = useState(0);
  const { account, library } = useWeb3React();

  const mint = async () => {
    if (!account) {
      toast.warning("Please connect your wallet first");
      return;
    }
    if (ttAmount < count * price) {
      toast.warning("Please swap to get more Apes token first");
      return;
    }
    toast.promise(mintNFT(count), {
      pending: "getting warriors now...",
      success: {
        render: (resp) => {
          const mintResp = resp.data;
          if (mintResp.success && mintResp.survivorIDs) {
            setNow((prev) => prev + count);

            setTtAmount((prev) =>
              ethers.BigNumber.from(prev).sub(
                ethers.BigNumber.from(price).mul(ethers.BigNumber.from(count))
              )
            );
            for (let i = 0; i < mintResp.survivorIDs.length; i++) {
              const survivor = mintResp.survivorIDs[i];
              if (i === mintResp.survivorIDs.length - 1) {
                return `You have got a new warrior:${survivor.toString()}`;
              }
              toast.success(
                `You have got a new warrior:${survivor.toString()}`
              );
            }
          }
          return `Get warriors failed!`;
        },
      },
      error: {
        render: (err) => {
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message === ContractErrors.ERC20BalanceNotEnough
            ) {
              return ContractErrorMsgs.ERC20BalanceNotEnoughMsg;
            }
            console.log(err.data.data.message);
            return err.data.data.message;
          }
          return "get warriors failed!";
        },
      },
    });
  };
  const swapTT = async () => {
    // TODO: swap
    toast.warning("coming soon!");
  };

  // listen account
  useEffect(() => {
    const getInfo = async () => {
      // price
      const priceNow = await getPrice();
      if (priceNow) {
        setPrice(priceNow);
      }
      // now
      const nowMint = await getTotalFoundedSurvivors();
      setNow(nowMint);
      // total
      const totalMint = await getTotalSurvivors();
      setTotal(totalMint);
      // currentTT
      const ttAmountNow = await getTTBalance(account);
      setTtAmount(ttAmountNow);
    };
    if (account && library) {
      try {
        getInfo();
      } catch (e) {
        console.log(e);
      }
    }
    return () => {};
  }, [account, library]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Hidden mdDown>
        <div
          style={{
            width: 1280,
            marginTop: 50,
            paddingTop: 200,
            background: `url(${MintBG}) no-repeat`,
            backgroundSize: "100% auto",
            backgroundPosition: "top",
          }}
        >
          <Stack flexDirection={"column"}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                NFT
              </Typography>
            </div>
            <div className={classes["nft-images-container"]}>
              <Grid container spacing={4}>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage0} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage1} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage3} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage4} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage5} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage6} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage7} />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                marginTop: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 100,
                }}
              >
                <CardMedia
                  component={"img"}
                  src={MintTitleImage}
                  style={{ width: 350 }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component={"img"}
                  image={BoxImg}
                  style={{ width: "60%" }}
                />
              </div>
              <div style={{ padding: "0 15%" }}>
                <div
                  style={{
                    paddingBottom: 30,
                    fontSize: 23,
                    lineHeight: "40px",
                    color: "#FFF",
                  }}
                >
                  {contents.description}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "15px 0",
                    fontSize: 27,
                    fontWeight: 700,
                  }}
                >
                  <div>NFT Contract Address：</div>
                  <div>
                    <Button
                      href={contractBaseUrl + nftContractAddress}
                      target="_blank"
                      style={{
                        margin: "0 20px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={LinkIcon}
                        style={{ width: 25, color: "#FFF" }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: 60,
                    background: "#0f6051",
                    boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                    borderRadius: 15,
                    border: "2px solid #39ff7b",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFF",
                      width: "80%",
                      overflow: "hidden",
                      paddingLeft: 20,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {nftContractAddress}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "15px 0",
                    fontSize: 27,
                    fontWeight: 700,
                  }}
                >
                  <div>Token Contract Address：</div>
                  <div>
                    <Button
                      href={contractBaseUrl + tokenContractAddress}
                      target="_blank"
                      style={{
                        margin: "0 20px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={LinkIcon}
                        style={{ width: 25, color: "#FFF" }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: 60,
                    background: "#0f6051",
                    boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                    borderRadius: 15,
                    border: "2px solid #39ff7b",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFF",
                      width: "80%",
                      overflow: "hidden",
                      paddingLeft: 20,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {tokenContractAddress}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "10px 0",
                    fontSize: 23,
                    fontWeight: 700,
                  }}
                >
                  Start time:{" "}
                  <span style={{ paddingLeft: 10, fontSize: 21 }}>
                    {startTime}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "0 0 10px",
                    fontSize: 23,
                    fontWeight: 700,
                  }}
                >
                  Price:{" "}
                  <span style={{ paddingLeft: 10, fontSize: 21 }}>
                    {formatBigNumber(price)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "0 0 10px",
                    fontSize: 23,
                    fontWeight: 700,
                  }}
                >
                  APES Amount:{" "}
                  <span style={{ paddingLeft: 10, fontSize: 21 }}>
                    {formatBigNumber(ttAmount)}
                  </span>
                  <Button
                    onClick={swapTT}
                    style={{
                      width: 100,
                      height: 40,
                      background:
                        "linear-gradient(to bottom, #39fe7b, #106552)",
                      boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                      borderRadius: 4,
                      color: "#FFF",
                      fontWeight: 700,
                      marginLeft: 60,
                    }}
                  >
                    Swap now
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "10px 0",
                    fontSize: 19,
                    fontWeight: 700,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      if (count <= 1) {
                        return;
                      }
                      setCount((prev) => prev - 1);
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      background:
                        "linear-gradient(to bottom, #39fe7b, #106552)",
                      boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                      borderRadius: 5,
                      color: "#FFF",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <div
                    style={{
                      margin: "0 10px",
                      width: 120,
                      textAlign: "center",
                      fontSize: 27,
                      color: "#F3CD72",
                    }}
                  >
                    {count}
                  </div>
                  <IconButton
                    onClick={() => {
                      setCount((prev) => prev + 1);
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      background:
                        "linear-gradient(to bottom, #39fe7b, #106552)",
                      boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                      borderRadius: 5,
                      color: "#FFF",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "35px 0 10px",
                    fontSize: 19,
                    fontWeight: 700,
                  }}
                >
                  <MintProgress now={now} total={total} />
                </div>
                <div className={classes["buy-btn-container"]}>
                  <Button
                    fullWidth
                    className={classes["buy-btn"]}
                    onClick={mint}
                    style={{
                      border: "2px solid #0ed0ec",
                    }}
                  >
                    MINT
                  </Button>
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            height: "fit-content",
            width: "100%",
            paddingTop: 60,
            background: `url(${MintBG}) no-repeat`,
            backgroundSize: "100% 100%",
            backgroundPosition: "top",
          }}
        >
          <Stack flexDirection={"column"}>
            <div className={classes["m-title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                NFT
              </Typography>
            </div>
            <div className={classes["m-nft-images-container"]}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage0} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage1} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage3} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage0} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage1} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardMedia component={"img"} src={NFTImage3} />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                marginTop: 30,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 40,
                }}
              >
                <CardMedia
                  component={"img"}
                  src={MintTitleImage}
                  style={{ width: 300 }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component={"img"}
                  image={BoxImg}
                  style={{ width: "60%" }}
                />
              </div>
              <div style={{ padding: "0 10%" }}>
                <div
                  style={{
                    paddingBottom: 30,
                    fontSize: 19,
                    lineHeight: "26px",
                    color: "#FFF",
                  }}
                >
                  {contents.description}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "15px 0",
                    fontSize: 21,
                    fontWeight: 700,
                  }}
                >
                  <div>NFT Contract Address：</div>
                  <div>
                    <Button
                      href={contractBaseUrl + nftContractAddress}
                      target="_blank"
                      style={{
                        margin: "0 20px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={LinkIcon}
                        style={{ width: 25, color: "#FFF" }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: 60,
                    background: "#0f6051",
                    boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                    borderRadius: 15,
                    border: "2px solid #39ff7b",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFF",
                      width: "80%",
                      overflow: "hidden",
                      paddingLeft: 20,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {nftContractAddress}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "15px 0",
                    fontSize: 21,
                    fontWeight: 700,
                  }}
                >
                  <div>Token Contract Address：</div>
                  <div>
                    <Button
                      href={contractBaseUrl + tokenContractAddress}
                      target="_blank"
                      style={{
                        margin: "0 20px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        src={LinkIcon}
                        style={{ width: 25, color: "#FFF" }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: 60,
                    background: "#0f6051",
                    boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                    borderRadius: 15,
                    border: "2px solid #39ff7b",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFF",
                      width: "80%",
                      overflow: "hidden",
                      paddingLeft: 20,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {tokenContractAddress}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "10px 0",
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  Start time:{" "}
                  <span style={{ paddingLeft: 5, fontSize: 17 }}>
                    {startTime}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "0 0 10px",
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  Price:{" "}
                  <span style={{ paddingLeft: 5, fontSize: 17 }}>
                    {formatBigNumber(price)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "0 0 10px",
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  APES Amount:{" "}
                  <span style={{ paddingLeft: 5, fontSize: 17 }}>
                    {formatBigNumber(ttAmount)}
                  </span>
                </div>
                <Button
                  onClick={swapTT}
                  style={{
                    width: 100,
                    height: 40,
                    background: "linear-gradient(to bottom, #39fe7b, #106552)",
                    boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                    borderRadius: 4,
                    color: "#FFF",
                    fontWeight: 700,
                  }}
                >
                  Swap now
                </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "10px 0",
                    fontSize: 19,
                    fontWeight: 700,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      if (count <= 1) {
                        return;
                      }
                      setCount((prev) => prev - 1);
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      background:
                        "linear-gradient(to bottom, #39fe7b, #106552)",
                      boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                      borderRadius: 5,
                      color: "#FFF",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <div
                    style={{
                      margin: "0 10px",
                      width: 120,
                      textAlign: "center",
                      fontSize: 27,
                      color: "#F3CD72",
                    }}
                  >
                    {count}
                  </div>
                  <IconButton
                    onClick={() => {
                      setCount((prev) => prev + 1);
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      background:
                        "linear-gradient(to bottom, #39fe7b, #106552)",
                      boxShadow: "0px 2px 20px 0px rgba(255, 255, 255, 0)",
                      borderRadius: 5,
                      color: "#FFF",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "#FFF",
                    padding: "35px 0 10px",
                    fontSize: 19,
                    fontWeight: 700,
                  }}
                >
                  <MintProgress now={now} total={total} />
                </div>
                <div className={classes["m-buy-btn-container"]}>
                  <Button
                    fullWidth
                    className={classes["buy-btn"]}
                    onClick={mint}
                    style={{
                      border: "2px solid #0ed0ec",
                    }}
                  >
                    MINT
                  </Button>
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </Hidden>
    </div>
  );
};
