import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Stack,
  Typography,
  Backdrop,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import GoldIcon from "../../assets/images/gold.png";
import { claim, rescue } from "../../client/mine";
import { now } from "moment";
import { formatUniteNumber } from "../../utils";
import BarAnim from "../../assets/images/bars.svg";
import { toast } from "react-toastify";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import tempImg from "../../assets/images/mint/mint_box.png";
import { ContractErrorMsgs, ContractErrors } from "../../constants";

export const StakeCharacterCard = (props) => {
  const {
    character,
    dailyIncome,
    setRescueID,
    deSelectRescueID,
    setSelectedRescueID,
    shouldClaiming,
    shouldRescuing,
    canBeSelected,
  } = props;
  const [char, setChar] = useState(character);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setChar(character);
    return () => {};
  }, [character]);

  useEffect(() => {
    if (shouldClaiming) {
      setClaiming(true);
    } else {
      setClaiming(false);
    }
    if (shouldRescuing) {
      setRescuing(true);
    } else {
      setRescuing(false);
    }
    if (!shouldClaiming && !shouldRescuing) {
      setSelected(false);
    }
    return () => {};
  }, [shouldClaiming, shouldRescuing]);

  const [claiming, setClaiming] = React.useState(false);
  const [rescuing, setRescuing] = useState(false);

  const claimSurvivor = async () => {
    if (claiming) {
      console.log("claiming...");
      return;
    }
    setClaiming(true);
    toast.promise(claim([char.tokenId]), {
      pending: "claiming now...",
      success: {
        render: (resp) => {
          setClaiming(false);
          const claimResp = resp.data;
          if (claimResp.success && claimResp.survivors) {
            let someone = null;
            if (
              claimResp.survivors.some((survivor) => {
                if (
                  survivor.survivorID.toString() === char.tokenId.toString()
                ) {
                  someone = survivor;
                  return true;
                }
                return false;
              })
            ) {
              return `You have successfully claimed your ${
                someone.isApe ? "ape" : "human"
              } warrior:${someone.survivorID.toString()}`;
            }
          }
          return `Claim  failed!`;
        },
      },
      error: {
        render: (err) => {
          setClaiming(false);
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message ===
              ContractErrors.MineCantUnStakeBeforeStakedForDays
            ) {
              return ContractErrorMsgs.MineCantUnStakeBeforeStakedForDaysMsg;
            } else if (
              err.data.data.message ===
              ContractErrors.ERC20TransferBalanceNotEnough
            ) {
              return ContractErrorMsgs.ERC20TransferBalanceNotEnoughMsg;
            }
            console.log(err.data.data.message);
            return err.data.data.message;
          }
          return "Claim warrior failed!";
        },
      },
    });
  };
  const rescueSurvivor = async () => {
    if (rescuing) {
      console.log("rescuing...");
      return;
    }
    setRescuing(true);
    toast.promise(rescue([char.tokenId]), {
      pending: "Rescuing warrior now...",
      success: {
        render: (resp) => {
          setRescuing(false);
          const claimResp = resp.data;
          if (claimResp.success && claimResp.survivors) {
            let someone = null;
            if (
              claimResp.survivors.some((survivor) => {
                if (
                  survivor.survivorID.toString() === char.tokenId.toString()
                ) {
                  someone = survivor;
                  return true;
                }
                return false;
              })
            ) {
              setRescueID(char.tokenId);
              return `You have successfully rescued your ${
                someone.isApe ? "ape" : "human"
              } warrior:${someone.survivorID.toString()}`;
            }
            return `Rescue warrior failed!`;
          }
          return `Rescue warrior failed!`;
        },
      },
      error: {
        render: (err) => {
          setRescuing(false);
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message ===
              ContractErrors.MineCantUnStakeBeforeStakedForDays
            ) {
              return ContractErrorMsgs.MineCantUnStakeBeforeStakedForDaysMsg;
            }
            console.log(err.data.data.message);
            return err.data.data.message;
          }
          return "Rescue warriors failed!";
        },
      },
    });
  };

  function calRewards(char) {
    return formatUniteNumber(
      Math.floor(
        (dailyIncome / 24 / 3600) *
          Math.floor(Math.abs(now() / 1000 - char.value.toNumber()))
      )
    );
  };

  const getRace = () => {
    if (!char.isOpen) {
      return "Unknown";
    }
    return char.isApe ? "Ape" : "Human";
  };
  return (
    <div>
      <Card
        sx={{ maxWidth: 240, maxHeight: 600 }}
        style={{
          background: "#0c2316",
          margin: 32,
          position: "relative",
        }}
      >
        <CardContent style={{ padding: 0 }}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              maxWidth: 240,
              maxHeight: 240,
              position: "absolute",
            }}
            open={claiming || rescuing}
          >
            <CardMedia component={"img"} src={BarAnim} style={{ width: 30 }} />
          </Backdrop>
          <CheckBoxIcon
            sx={{
              position: "absolute",
              color: "green",
              background: "rgba(0, 0, 0, .6)",
              display: selected ? "block" : "none",
            }}
          />
          <CardMedia
            component="img"
            height="240"
            image={char.isOpen ? char.imageUrl : tempImg}
            alt="Paella dish"
            style={{
              background: "linear-gradient(to bottom, #0f6051, #39fd7b)",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelected((prev) => {
                if (prev) {
                  deSelectRescueID(char.tokenId);
                } else {
                  if (canBeSelected) {
                    setSelectedRescueID(char.tokenId);
                  } else {
                    toast.warn("Only five survors could be selected on time!");
                    return prev;
                  }
                }
                return !prev;
              });
            }}
          />
        </CardContent>
        <CardContent style={{ padding: 0 }}>
          <div
            variant="body2"
            color="#000"
            width={"240"}
            style={{
              width: "240px",
              height: "180px",
              background: "#0c2316",
              borderRadius: "0px 0px 4px 4px",
              color: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "30px",
            }}
          >
            <Typography>No: {char.tokenId.toString()}</Typography>
            <Typography>Race: {getRace()}</Typography>
            <Typography>
              Attack: {char.isOpen ? char.attack : "Unknown"}
            </Typography>
            <Typography>
              Defense: {char.isOpen ? char.defense : "Unknown"}
            </Typography>
            <Typography>
              Speed: {char.isOpen ? char.speed : "Unknown"}
            </Typography>
            <div style={{ height: 30, paddingTop: 5, display: "flex" }}>
              <img alt="gold" src={GoldIcon} style={{ height: 25 }} />
              <div
                style={{
                  display: "inline-block",
                  color: "#F2CC71",
                  fontSize: 21,
                  fontWeight: 700,
                  paddingLeft: 10,
                }}
              >
                {calRewards(char)}
              </div>
            </div>
          </div>
        </CardContent>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          style={{ position: "relative" }}
        >
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              maxWidth: 240,
              position: "absolute",
              backgroundColor: "#333",
            }}
            open={claiming || rescuing}
          >
            {claiming && "CLAIMING..."}
            {rescuing && "RESCUING..."}
          </Backdrop>
          <Button
            onClick={claimSurvivor}
            style={{
              width: 112,
              height: 50,
              background: "linear-gradient(to bottom, #39ff7b, #0f6051)",
              color: "#FFF",
            }}
          >
            Claim
          </Button>

          <Button
            onClick={rescueSurvivor}
            style={{
              width: 112,
              height: 50,
              background: "linear-gradient(to bottom, #39ff7b, #0f6051)",
              color: "#FFF",
            }}
          >
            Rescue
          </Button>
        </Stack>
      </Card>
    </div>
  );
};
