import React from "react";
import classes from "./Home.module.scss";

import {
  Header,
  HeadBanner,
  About,
  NFT,
  Footer,
  RoadMap,
  Token,
} from "../../components";

const HomePage = () => {
  return (
    <div className={classes.home}>
      {/* header */}
      <Header />
      {/* head banner */}
      <HeadBanner />
      {/* about */}
      <About />
      {/* token */}
      <Token />
      {/* nft */}
      <NFT />
      {/* roadmap */}
      <RoadMap />
      {/* footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
