import { CardMedia, Hidden, Stack, Typography } from "@mui/material"
import ReactEcharts from "echarts-for-react"
import React from "react"
import SectionTitleBG from "../../assets/images/section/section_title_bg.png"
import classes from "./MyToken.module.scss"

const contents = {
  description: `
Ape token is a standard ERC20 contract with a unique token sharing mechanism, issued on the Base chian. As the founder of a new world, every successful invitation you make will bring you a certain amount of token revenue. The investor supplying the liquidity will also earn a percentage of every trade. Holding NFTs will be the only way for you to get new tokens!
`,
}

export const MyToken = () => {
  const getOption = (_fontSize) => ({
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    color: ["#329d9c", "#56c596", "#7be495", "#a0e894", "#bbeb93"],

    series: [
      {
        name: "token allocation",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        animationDuration: 1000,
        data: [
          { value: 180000000, name: "LP" },
          { value: 20000000, name: "Airdrop" },
          { value: 800000000, name: "Mine" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          color: "#FFF",
          fontSize: _fontSize,
        },
        labelLine: {
          show: true,
          showAbove: true,
        },
      },
    ],
  })

  return (
    <div>
      <Hidden mdDown>
        <div className={classes["introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                Token
              </Typography>
            </div>
            <Stack
              direction={"column"}
              className={classes["token-desc-container"]}
            >
              <Typography
                component={"div"}
                style={{
                  paddingBottom: 30,
                  fontSize: 23,
                  lineHeight: "40px",
                  color: "#FFF",
                  fontWeight: 700,
                }}
              >
                {contents.description}
              </Typography>
              <div
                style={{
                  position: "relative",
                  height: 800,
                }}
              >
                <ReactEcharts
                  option={getOption(21)}
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
            </Stack>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes["m-introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                Token
              </Typography>
            </div>
            <Stack
              direction={"column"}
              className={classes["token-desc-container"]}
            >
              <Typography
                component={"div"}
                style={{
                  paddingBottom: 10,
                  fontSize: 17,
                  lineHeight: "30px",
                  color: "#FFF",
                  fontWeight: 700,
                }}
              >
                {contents.description}
              </Typography>
              <div
                style={{
                  position: "relative",
                  height: 300,
                }}
              >
                <ReactEcharts
                  option={getOption(11)}
                  style={{ height: "300px", width: "100%" }}
                />
              </div>
            </Stack>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
