import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Backdrop,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { stake } from "../../client/mine";
import { toast } from "react-toastify";
import BarAnim from "../../assets/images/bars.svg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import tempImg from "../../assets/images/mint/mint_box.png";
import { ContractErrorMsgs, ContractErrors } from "../../constants";

export const MineCharacterCard = (props) => {
  const {
    character,
    setStakeID,
    setSelectedStakeID,
    deSelectStakeID,
    shouldStaking,
    canBeSelected,
  } = props;
  const { account } = useWeb3React();
  const [char, setChar] = useState(character);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setChar(character);
    setSelected(false);
    return () => {};
  }, [character]);

  const [staking, setStaking] = React.useState(false);

  const stakeToEarn = async () => {
    if (staking) {
      console.log("staking...");
      return;
    }

    setStaking(true);
    toast.promise(stake(account, [char.tokenId]), {
      pending: "Staking warriors now...",
      success: {
        render: (resp) => {
          setStaking(false);
          const mintResp = resp.data;
          if (mintResp.success && mintResp.survivorIDs) {
            if (
              mintResp.survivorIDs.some(
                (Id) => Id.toString() === char.tokenId.toString()
              )
            ) {
              setStakeID(char.tokenId);
              return `You have successfully staked your warrior:${char.tokenId.toString()}`;
            }
            return `Stake warriors failed!`;
          }
          return `Stake warriors failed!`;
        },
      },
      error: {
        render: (err) => {
          setStaking(false);
          console.log(err);
          if (err.data && err.data.data && err.data.data.message) {
            if (
              err.data.data.message === ContractErrors.ERC721CantStakedDirectly
            ) {
              return ContractErrorMsgs.ERC721CantStakedDirectlyMsg;
            }
            return err.data.data.message;
          }
          return "Stake warriors failed!";
        },
      },
    });
  };

  const getRace = () => {
    if (!char.isOpen) {
      return "Unknown";
    }
    return char.isApe ? "Ape" : "Human";
  };

  useEffect(() => {
    if (shouldStaking) {
      setStaking(true);
    } else {
      setStaking(false);
    }
    return () => {};
  }, [shouldStaking]);

  return (
    <>
      <Card
        sx={{ maxWidth: 240, maxHeight: 480 }}
        style={{
          background: "#0c2316",
          margin: 32,
          position: "relative",
        }}
      >
        <CardContent style={{ padding: 0 }}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              maxWidth: 240,
              maxHeight: 240,
              position: "absolute",
            }}
            open={staking}
          >
            <CardMedia component={"img"} src={BarAnim} style={{ width: 30 }} />
          </Backdrop>
          <CheckBoxIcon
            sx={{
              position: "absolute",
              color: "green",
              background: "rgba(0, 0, 0, .6)",
              display: selected ? "block" : "none",
            }}
          />
          <CardMedia
            component="img"
            height="240"
            image={char.isOpen ? char.imageUrl : tempImg}
            alt="Paella dish"
            style={{
              background: "linear-gradient(to bottom, #0f6051, #39fd7b)",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelected((prev) => {
                if (prev) {
                  deSelectStakeID(char.tokenId);
                } else {
                  if (canBeSelected) {
                    setSelectedStakeID(char.tokenId);
                  } else {
                    toast.warn("Only five NFTs could be selected on time!");
                    return prev;
                  }
                }
                return !prev;
              });
            }}
          />
        </CardContent>
        <CardContent style={{ padding: 0 }}>
          <Typography
            variant="body2"
            color="#000"
            width={"240"}
            style={{
              width: "240px",
              height: "180px",
              background: "#0c2316",
              color: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              paddingLeft: "30px",
            }}
          >
            <Typography>No: {char.tokenId.toString()}</Typography>
            <Typography>Race: {getRace()}</Typography>
            <Typography>
              Attack: {char.isOpen ? char.attack : "Unknown"}
            </Typography>
            <Typography>
              Defense: {char.isOpen ? char.defense : "Unknown"}
            </Typography>
            <Typography>
              Speed: {char.isOpen ? char.speed : "Unknown"}
            </Typography>
          </Typography>
        </CardContent>
        <Button
          onClick={stakeToEarn}
          style={{
            width: "100%",
            height: "80",
            background: "linear-gradient(to bottom, #39ff7b, #0f6051)",
            color: "#FFF",
            borderRadius: 4,
          }}
        >
          {staking ? "Staking..." : "Stake to Earn"}
        </Button>
      </Card>
    </>
  );
};
