export const openTime = {
  year: 2023,
  month: 8,
  day: 1,
  hour: 20,
  minute: 0,
  second: 0,
}

export const ContractErrors = {
  ERC20BalanceNotEnough:
    "execution reverted: ERC20: burn amount exceeds balance",
  ERC20TransferBalanceNotEnough:
    "execution reverted: ERC20: transfer amount exceeds balance",
  ERC721CantStakedDirectly:
    "execution reverted: Mine: cannot send tokens to stakedNFTs directly",
  MineCantUnStakeBeforeStakedForDays:
    "execution reverted: Mine: invalid days to exit",
}

export const ContractErrorMsgs = {
  ERC20BalanceNotEnoughMsg: "Swap to get more APES token!",
  ERC20TransferBalanceNotEnoughMsg: "Transfer amount exceeds balance",
  ERC721CantStakedDirectlyMsg: "NFT can't be staked directly!",
  MineCantUnStakeBeforeStakedForDaysMsg:
    "Rescuing or claiming is only available when have staked for at least one day!",
}
export const MediaChannels = {
  twitter: "https://twitter.com/BaseApeGame",
  medium: "https://medium.com/BaseApeGame",
  telegram: "https://t.me/BaseApeGame",
}

// TODO: update online link
export const pinksale = {
  url: "/nft",
}

// TODO: modify this for production, see contract
export const contractBaseUrl = "https://basescan.com/address/"
// TODO: modify this for production, see nft photo collection
export const nftTradeBaseUrl = ""
// TODO: json url for googleapi
export const jsonBaseUrl = "https://apesworld.s3.ap-southeast-1.amazonaws.com/"
