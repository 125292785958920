import SendIcon from "@mui/icons-material/Send"
import { CardMedia, Grid, Hidden, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import React from "react"
import NFTImage0 from "../../assets/images/about/nft_image_0.png"
import NFTImage1 from "../../assets/images/about/nft_image_1.png"
import NFTImage2 from "../../assets/images/about/nft_image_2.png"
import NFTImage3 from "../../assets/images/about/nft_image_3.png"
import SectionTitleBG from "../../assets/images/section/section_title_bg.png"
import classes from "./NFT.module.scss"

const contents = {
  description: `
  Biochemical experiments and over-exploitation of natural resources continue to cause great damage to the ecological environment. When we threaten the planet, we are undermining our only home in the foreseeable future. This time, based on a virtual reality where the collapse of ecological balance has brought out an all-out war between human and mutated apes, creators present 10,000 NFT images of the apes and humans both affected by the environment to call on people today to pay continuous attention to environmental protection in the process of development.
`,
}

export const NFT = () => {
  return (
    <div>
      <Hidden mdDown>
        <div className={classes["introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                NFT
              </Typography>
            </div>
            <div className={classes["nft-images-container"]}>
              <Grid container>
                <Grid container item xs={12} md={6} spacing={2}>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage0} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage1} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage2} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage3} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography
                      component="div"
                      style={{
                        fontSize: 21,
                        lineHeight: "40px",
                        color: "#FFF",
                        paddingLeft: "40px",
                      }}
                    >
                      {contents.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: 50,
                paddingRight: "15%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ color: "#1bec65", border: "1px solid #1bec65" }}
                variant="outlined"
                href="https://twitter.com/BaseApeGame"
                endIcon={<SendIcon />}
              >
                Follow us
              </Button>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes["m-introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                NFT
              </Typography>
            </div>
            <div className={classes["nft-images-container"]}>
              <Grid container>
                <Grid container item xs={12} md={6} spacing={2}>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage0} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage1} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage2} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardMedia component={"img"} src={NFTImage3} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography
                      component="div"
                      style={{
                        fontSize: 21,
                        lineHeight: "30px",
                        color: "#FFF",
                        paddingTop: "30px",
                      }}
                    >
                      {contents.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: 30,
                paddingRight: "15%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ color: "#1bec65", border: "1px solid #1bec65" }}
                variant="outlined"
                href="https://twitter.com/BaseApeGame"
                endIcon={<SendIcon />}
              >
                Follow us
              </Button>
            </div>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
