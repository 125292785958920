import React, { useState } from "react"

import { Menu } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button,
  CardMedia,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { FaTelegram } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import Logo from "../../assets/images/header/logo.png"
import nftTradeIcon from "../../assets/images/nftTrade.png"
import TwitterIcon from "../../assets/images/twitter.svg"
import { ToggleLoginButton } from "../../client"
import { MediaChannels } from "../../constants"

const useStyles = makeStyles((theme) => ({
  muiDrawer: {
    backgroundColor: "#0c2316",
  },
  list: {
    width: "75vw",
    backgroundColor: "#0c2316",
    height: "100vh",
  },
  linkText: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
  },
  "buy-btn-container": {
    display: "flex",
    justifyContent: "center",
    padding: "15px 10px",
  },
  "buy-btn": {
    background: "linear-gradient(to right, #434bba, #6d3a88)",
    fontSize: 14,
    fontWeight: 700,
    color: "#FFF",
    padding: 10,
  },
  listItem: {
    borderBottom: "1px solid #22b573",
    color: "#FFF",
    height: 58,
  },
  "bottom-btn-container": {
    display: "flex",
    justifyContent: "center",
    padding: "5px 10px",
  },
  "bottom-btn": {
    border: "1px solid #22b573",
    fontSize: 12,
    fontWeight: 700,
    color: "#FFF",
    "&:last-child": {
      marginLeft: 20,
    },
    "&:first-child": {
      marginLeft: 0,
    },
  },
}))

export const SideDrawer = ({ scrollToAnchor }) => {
  const classes = useStyles()
  const [state, setState] = useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ [anchor]: open })
  }

  // methods
  let navigate = useNavigate()
  let match = useLocation()
  // TODO: fixed this buy on nft trade
  const openOpensea = () => {
    // TODO:
  }

  const jumpToHome = () => {
    setState({
      right: false,
    })
    if (match !== "/") {
      navigate("/")
    }
  }
  const jumpToGame = () => {
    setState({
      right: false,
    })
    if (match !== "/game") {
      navigate("/game")
    }
    scrollToAnchor("metaverse")
  }
  const jumpToRoadMap = () => {
    setState({
      right: false,
    })
    if (match !== "/") {
      navigate("/")
    }
    scrollToAnchor("roadmap")
  }

  const jumpToNFT = () => {
    setState({
      right: false,
    })
    if (match !== "/nft") {
      navigate("/nft")
    }
  }
  const jumpToWhitepaper = () => {
    setState({
      right: false,
    })
    const w = window.open("/whitepaper", "_blank")
    w.focus()
  }

  const jumpToToken = () => {
    setState({
      right: false,
    })
    if (match !== "/token") {
      navigate("/token")
    }
  }

  return (
    <div>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <Menu fontSize="large" style={{ color: "#FFF" }} />
      </IconButton>
      <Drawer
        classes={{ paper: classes.muiDrawer }}
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        children={
          <div
            className={classes.list}
            onKeyDown={toggleDrawer("right", false)}
          >
            <List
              component="nav"
              subheader={
                <ListSubheader
                  component="div"
                  style={{
                    background: "linear-gradient(to right, #0f6051, #39ff7b)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "75px",
                  }}
                >
                  <Box paddingTop={"10px"}>
                    <img src={Logo} alt="logo" height={"40"} />
                  </Box>
                  <IconButton
                    onClick={toggleDrawer("right", false)}
                    sx={{
                      color: "#FFF",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListSubheader>
              }
            >
              <div className={classes["buy-btn-container"]}>
                <ToggleLoginButton
                  onSuccess={() => {}}
                  onError={() => {}}
                  child={"LOGIN FOR LEGENDS"}
                />
              </div>
              <ListItemButton
                onClick={jumpToHome}
                sx={{ pl: 3 }}
                className={classes.listItem}
              >
                <Typography fontSize={20}>HOME</Typography>
              </ListItemButton>

              <ListItemButton
                onClick={jumpToToken}
                sx={{ pl: 3 }}
                className={classes.listItem}
              >
                <Typography fontSize={20}>TOKEN</Typography>
              </ListItemButton>
              <ListItemButton
                onClick={jumpToNFT}
                sx={{ pl: 3 }}
                className={classes.listItem}
              >
                <Typography fontSize={20}>NFT</Typography>
              </ListItemButton>
              <ListItemButton
                onClick={jumpToGame}
                sx={{ pl: 3 }}
                className={classes.listItem}
              >
                <Typography fontSize={20}>GAME</Typography>
              </ListItemButton>
              <ListItemButton
                onClick={jumpToRoadMap}
                sx={{ pl: 3 }}
                className={classes.listItem}
              >
                <Typography fontSize={20}>ROADMAP</Typography>
              </ListItemButton>
            </List>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                paddingBottom: 30,
                width: "100%",
              }}
            >
              <div className={classes["bottom-btn-container"]}>
                <Button
                  fullWidth
                  className={classes["bottom-btn"]}
                  startIcon={
                    <CardMedia
                      component={"img"}
                      src={TwitterIcon}
                      style={{ width: 25 }}
                    />
                  }
                  href={MediaChannels.twitter}
                >
                  <div>JOIN OUR TWITTER</div>
                </Button>
                <Button
                  fullWidth
                  className={classes["bottom-btn"]}
                  startIcon={<FaTelegram size={26} />}
                  href={MediaChannels.telegram}
                >
                  <div>JOIN OUR TELEGRAM</div>
                </Button>
              </div>
              <div div className={classes["bottom-btn-container"]}>
                <Button
                  fullWidth
                  className={classes["bottom-btn"]}
                  startIcon={
                    <CardMedia
                      component={"img"}
                      src={nftTradeIcon}
                      style={{ width: 30, borderRadius: 4, marginRight: 5 }}
                      onClick={openOpensea}
                    />
                  }
                >
                  <div>Buy on nft trade</div>
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}
