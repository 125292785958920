import { CardMedia, Hidden, IconButton, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useEffect, useRef, useState } from "react"
import MintButtonIcon from "../../assets/images/headbanner/mint_btn.png"
import { openTime, pinksale } from "../../constants"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    color: "#fdd323",
  },
})

export const Countdown = (props) => {
  const { onComing, onStart } = props
  const [num, setNum] = useState(0)
  const classes = useStyles()

  let intervalRef = useRef()

  const decreaseNum = () => {
    setNum((prev) => {
      if (prev <= 0) {
        clearInterval(intervalRef.current)
        return 0
      }
      return prev - 1
    })
  }

  useEffect(() => {
    // modify end time to real end time
    const endTime = new Date(
      Date.UTC(
        openTime.year,
        openTime.month - 1,
        openTime.day,
        openTime.hour,
        openTime.minute,
        openTime.second
      )
    ).getTime()
    const startTime = new Date().getTime()
    var total
    total = Math.floor((endTime - startTime) / 1000)

    console.log(startTime)
    console.log(endTime)
    setNum(total)
    intervalRef.current = setInterval(decreaseNum, 1000)
    return () => clearInterval(intervalRef.current)
  }, [])

  return (
    <div className={classes.container}>
      {num > 0 && (
        <IconButton
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
          }}
          onClick={onComing}
        >
          <Hidden mdDown>
            <a href={pinksale.url} style={{ textDecoration: "none" }}>
              <CardMedia
                title="background"
                image={MintButtonIcon}
                style={{ backgroundSize: "100% 100%" }}
              >
                <Typography
                  style={{
                    height: "74px",
                    width: "350px",
                    lineHeight: "74px",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "21px",
                  }}
                >
                  Mint
                </Typography>
              </CardMedia>
            </a>
          </Hidden>
          <Hidden mdUp>
            <a href={pinksale.url} style={{ textDecoration: "none" }}>
              <CardMedia
                title="background"
                image={MintButtonIcon}
                style={{ backgroundSize: "100% 100%" }}
              >
                <Typography
                  style={{
                    height: "60px",
                    width: "180px",
                    lineHeight: "60px",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  Mint
                </Typography>
              </CardMedia>
            </a>
          </Hidden>
        </IconButton>
      )}
      {num === 0 && (
        <IconButton
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 0,
          }}
          onClick={onStart}
        >
          <Hidden mdDown>
            <a href={"/nft"} style={{ textDecoration: "none" }}>
              <CardMedia
                title="background"
                image={MintButtonIcon}
                style={{ backgroundSize: "100% 100%" }}
              >
                <Typography
                  style={{
                    height: "74px",
                    width: "350px",
                    lineHeight: "74px",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "21px",
                  }}
                >
                  MINT YOUR NFT
                </Typography>
              </CardMedia>
            </a>
          </Hidden>
          <Hidden mdUp>
            <a href={"/nft"} style={{ textDecoration: "none" }}>
              <CardMedia
                title="background"
                image={MintButtonIcon}
                style={{ backgroundSize: "100% 100%" }}
              >
                <Typography
                  style={{
                    height: "50px",
                    width: "150px",
                    lineHeight: "50px",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  MINT YOUR NFT
                </Typography>
              </CardMedia>
            </a>
          </Hidden>
        </IconButton>
      )}
    </div>
  )
}
