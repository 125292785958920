import React from "react";
import { Routes, Route } from "react-router-dom";
import classes from "./App.module.scss";
import HomePage from "./pages/Home/Home";
import NFTPage from "./pages/NFT/NFT";
import {
  useActivatingConnector,
  useEagerConnect,
  useInactiveListener,
} from "./client";
import TokenPage from "./pages/Token/Token";
import GamePage from "./pages/Game/Game";



const App = () => {
  const activatingConnector = useActivatingConnector();
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || activatingConnector);
  return (
    <div className={classes.App}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/token" element={<TokenPage />} />
        <Route path="/nft" element={<NFTPage />} />
        <Route path="/game" element={<GamePage />} />
      </Routes>
    </div>
  );
};

export default App;
