import React from "react";
import classes from "./Token.module.scss";

import { Header, Footer, MyToken } from "../../components";

const TokenPage = () => {
  return (
    <div className={classes.nft}>
      {/* header */}
      <Header />
      {/* my token */}
      <MyToken />
      {/* footer */}
      <Footer />
    </div>
  );
};

export default TokenPage;
