import { Button, CardMedia, Hidden, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { FaMedium, FaTelegram, FaTwitter } from "react-icons/fa"
import ApesWorldLogo from "../../assets/images/header/logo.png"
import { MediaChannels } from "../../constants"
import classes from "./Footer.module.scss"

export const Footer = () => {
  return (
    <div>
      <Hidden mdDown>
        <div className={classes["footer-container"]}>
          <CardMedia
            component={"img"}
            image={ApesWorldLogo}
            style={{
              width: 160,
            }}
          />
          <Box
            style={{
              color: "#FFF",
              paddingTop: 50,
              paddingBottom: 10,
              fontSize: 21,
              display: "flex",
            }}
          >
            {/* <div style={{ padding: "0 15px" }}>Blog</div>
                        <div style={{ padding: "0 15px" }}>Lite Paper - Meta Legends</div>
                        <div style={{ padding: "0 15px" }}>Lite Paper - Meta Life</div>
                        <div style={{ padding: "0 15px" }}>contact@meta-legends.com</div>
                        <div style={{ padding: "0 15px" }}>Terms & conditions</div> */}
          </Box>
          <Stack
            style={{
              width: 1280,
              position: "relative",
            }}
            direction="row"
            justifyContent="center"
          >
            <div
              style={{
                position: "absolute",
                height: "50%",
                width: 1280,
                borderBottom: "1px solid",
                borderTop: "0 solid",
                borderLeft: "0 solid",
                borderRight: "0 solid",
                borderImage:
                  "linear-gradient(to left, #018a4b, #018a4b, #15d45f, #15d45f, #018a4b, #018a4b) 1",
                zIndex: 0,
              }}
            ></div>
            <Box style={{ zIndex: 1 }}>
              <Button
                href={MediaChannels.medium}
                style={{ margin: "0 20px", backgroundColor: "#000" }}
              >
                <FaMedium color="#FFF" size={50} />
              </Button>
              <Button
                href={MediaChannels.telegram}
                style={{ margin: "0 20px", backgroundColor: "#000" }}
              >
                <FaTelegram color="#FFF" size={50} />
              </Button>
              <Button
                href={MediaChannels.twitter}
                style={{ margin: "0 20px", backgroundColor: "#000" }}
              >
                <FaTwitter color="#FFF" size={50} />
              </Button>
            </Box>
          </Stack>
          <Typography
            style={{
              color: "#FFF",
              paddingTop: 50,
              paddingBottom: 50,
              fontSize: 21,
            }}
          >
            ©2023 BaseApeGame. All rights reserved.
          </Typography>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes["m-footer-container"]}>
          <CardMedia
            component={"img"}
            image={ApesWorldLogo}
            style={{
              width: 120,
            }}
          />
          {/* <Typography style={{
                        color: "#FFF",
                        paddingTop: 50,
                        paddingBottom: 50,
                        fontSize: 21,
                        display: "flex",
                    }}>
                        <div style={{ padding: "0 15px" }}>Blog</div>
                        <div style={{ padding: "0 15px" }}>Lite Paper - Meta Legends</div>
                        <div style={{ padding: "0 15px" }}>Lite Paper - Meta Life</div>
                        <div style={{ padding: "0 15px" }}>contact@meta-legends.com</div>
                        <div style={{ padding: "0 15px" }}>Terms & conditions</div>
                    </Typography> */}
          <Box style={{ marginTop: 30 }}>
            <Button href={MediaChannels.medium} style={{ padding: "0 15px" }}>
              <FaMedium color="#FFF" size={40} />
            </Button>
            <Button href={MediaChannels.telegram} style={{ padding: "0 15px" }}>
              <FaTelegram color="#FFF" size={40} />
            </Button>
            <Button href={MediaChannels.twitter} style={{ padding: "0 15px" }}>
              <FaTwitter color="#FFF" size={40} />
            </Button>
          </Box>
          <Typography
            style={{
              color: "#5F5B79",
              paddingTop: 30,
              paddingBottom: 30,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            ©2023 BaseApeGame. All rights reserved.
          </Typography>
        </div>
      </Hidden>
    </div>
  )
}
