import React from "react";
import classes from "./Game.module.scss";

import { Header, Footer, MyNft } from "../../components";

const GamePage = () => {
  return (
    <div className={classes.nft}>
      {/* header */}
      <Header />
      {/* my nft */}
      <MyNft />
      {/* footer */}
      <Footer />
    </div>
  );
};

export default GamePage;
