import SendIcon from "@mui/icons-material/Send"
import { Box, CardMedia, Hidden, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import React from "react"
import SectionTitleBG from "../../assets/images/section/section_title_bg.png"
import TokenCashBG from "../../assets/images/token/cash.png"
import TokenSectionBG from "../../assets/images/token/section_token_bg.png"
import classes from "./Token.module.scss"

const contents = {
  description: `Ape token is a standard ERC20 contract with a unique token sharing mechanism, issued on the Base chain. As the founder of a new world, every successful invitation you make will bring you a certain amount of token revenue. The investor supplying the liquidity will also earn a percentage of every trade. Holding NFTs will be the only way for you to get new tokens!`,
}
export const Token = () => {
  return (
    <div>
      <Hidden mdDown>
        <div className={classes["introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                Token
              </Typography>
            </div>
            <Typography
              component={"div"}
              className={classes["token-desc-container"]}
            >
              <CardMedia
                component={"img"}
                src={TokenCashBG}
                className={classes["token-cash-img"]}
              />
              <CardMedia
                component={"img"}
                src={TokenSectionBG}
                className={classes["token-section-img"]}
              />
              <Box className={classes["token-desc-content"]}>
                {contents.description}
              </Box>
              <Box
                component={"span"}
                style={{
                  position: "absolute",
                  bottom: 50,
                  right: "15%",
                  fontSize: 23,
                  lineHeight: "40px",
                  color: "#FFF",
                }}
              >
                <Button
                  style={{ color: "#1bec65", border: "1px solid #1bec65" }}
                  variant="outlined"
                  href="https://twitter.com/BaseApeGame"
                  endIcon={<SendIcon />}
                >
                  Follow us
                </Button>
              </Box>
            </Typography>
          </div>
        </div>
      </Hidden>

      <Hidden mdUp>
        <div className={classes["m-introduce-container"]}>
          <div className={classes["introduce-content"]}>
            <div className={classes["title-container"]}>
              <CardMedia
                component={"img"}
                src={SectionTitleBG}
                style={{
                  width: 185,
                }}
              />
              <Typography
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, #1bec65, #018a4b)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  textShadow: "0 0 32px rgb(159 55 208 / 79%)",
                  position: "absolute",
                  width: 130,
                  textAlign: "center",
                  top: -30,
                  fontSize: 23,
                  letterSpacing: 2,
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              >
                Token
              </Typography>
            </div>
            <Typography
              component={"div"}
              className={classes["token-desc-container"]}
            >
              <CardMedia
                component={"img"}
                src={TokenCashBG}
                className={classes["token-cash-img"]}
              />
              <CardMedia
                component={"img"}
                src={TokenSectionBG}
                className={classes["token-section-img"]}
              />
              <Box className={classes["token-desc-content"]}>
                {contents.description}
              </Box>
              <Box
                component={"span"}
                style={{
                  position: "absolute",
                  bottom: 30,
                  right: "10%",
                  fontSize: 23,
                  lineHeight: "40px",
                  color: "#FFF",
                }}
              >
                <Button
                  style={{ color: "#1bec65", border: "1px solid #1bec65" }}
                  variant="outlined"
                  href="https://twitter.com/BaseApeGame"
                  endIcon={<SendIcon />}
                >
                  Follow us
                </Button>
              </Box>
            </Typography>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
