import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import 'normalize.css';
import App from './App';
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from './theme';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLibrary } from "./client"


ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
    <ToastContainer />
  </Web3ReactProvider>,
  document.getElementById('root')
);
